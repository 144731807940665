import React from "react";

const CloseIcon = () => {
  return (
    <div>
      <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.2"
          d="M22.0992 8.5H28.8992C29.3501 8.5 29.7825 8.67911 30.1013 8.99792C30.4201 9.31673 30.5992 9.74913 30.5992 10.2V17C30.5992 17.4509 30.4201 17.8833 30.1013 18.2021C29.7825 18.5209 29.3501 18.7 28.8992 18.7H22.0992C21.6484 18.7 21.216 18.5209 20.8971 18.2021C20.5783 17.8833 20.3992 17.4509 20.3992 17V10.2C20.3992 9.74913 20.5783 9.31673 20.8971 8.99792C21.216 8.67911 21.6484 8.5 22.0992 8.5ZM11.8992 18.7H18.6992C19.1501 18.7 19.5825 18.8791 19.9013 19.1979C20.2201 19.5167 20.3992 19.9491 20.3992 20.4V27.2C20.3992 27.6509 20.2201 28.0833 19.9013 28.4021C19.5825 28.7209 19.1501 28.9 18.6992 28.9H11.8992C11.4484 28.9 11.0159 28.7209 10.6971 28.4021C10.3783 28.0833 10.1992 27.6509 10.1992 27.2V20.4C10.1992 19.9491 10.3783 19.5167 10.6971 19.1979C11.0159 18.8791 11.4484 18.7 11.8992 18.7Z"
          fill="#F2F3FB"
        />
        <path
          d="M19.3013 15.9013C19.1417 16.0609 18.9252 16.1506 18.6995 16.1506C18.4738 16.1506 18.2573 16.0609 18.0977 15.9013C17.9381 15.7417 17.8484 15.5252 17.8484 15.2995C17.8484 15.0738 17.9381 14.8573 18.0977 14.6977L24.8977 7.89771C24.9767 7.81868 25.0706 7.75599 25.1738 7.71322C25.2771 7.67045 25.3877 7.64844 25.4995 7.64844C25.6113 7.64844 25.7219 7.67045 25.8252 7.71322C25.9285 7.75599 26.0223 7.81868 26.1013 7.89771C26.1803 7.97674 26.243 8.07056 26.2858 8.17382C26.3286 8.27708 26.3506 8.38775 26.3506 8.49951C26.3506 8.61128 26.3286 8.72195 26.2858 8.8252C26.243 8.92846 26.1803 9.02228 26.1013 9.10131L19.3013 15.9013ZM9.10131 26.1013C9.02228 26.1803 8.92846 26.243 8.8252 26.2858C8.72195 26.3286 8.61128 26.3506 8.49951 26.3506C8.38775 26.3506 8.27708 26.3286 8.17382 26.2858C8.07056 26.243 7.97674 26.1803 7.89771 26.1013C7.81868 26.0223 7.75599 25.9285 7.71322 25.8252C7.67045 25.7219 7.64844 25.6113 7.64844 25.4995C7.64844 25.3877 7.67045 25.2771 7.71322 25.1738C7.75599 25.0706 7.81868 24.9767 7.89771 24.8977L14.6977 18.0977C14.8573 17.9381 15.0738 17.8484 15.2995 17.8484C15.5252 17.8484 15.7417 17.9381 15.9013 18.0977C16.0609 18.2573 16.1506 18.4738 16.1506 18.6995C16.1506 18.9252 16.0609 19.1417 15.9013 19.3013L9.10131 26.1013Z"
          fill="#F2F3FB"
        />
        <path
          d="M8.49844 19.3494C8.273 19.3494 8.0568 19.2599 7.8974 19.1005C7.73799 18.941 7.64844 18.7248 7.64844 18.4994C7.64844 18.274 7.73799 18.0578 7.8974 17.8984C8.0568 17.739 8.273 17.6494 8.49844 17.6494H15.2984C15.5239 17.6494 15.7401 17.739 15.8995 17.8984C16.0589 18.0578 16.1484 18.274 16.1484 18.4994C16.1484 18.7248 16.0589 18.941 15.8995 19.1005C15.7401 19.2599 15.5239 19.3494 15.2984 19.3494H8.49844Z"
          fill="#F2F3FB"
        />
        <path
          d="M16.3484 25.4994C16.3484 25.7248 16.2589 25.941 16.0995 26.1005C15.9401 26.2599 15.7239 26.3494 15.4984 26.3494C15.273 26.3494 15.0568 26.2599 14.8974 26.1005C14.738 25.941 14.6484 25.7248 14.6484 25.4994V18.6994C14.6484 18.474 14.738 18.2578 14.8974 18.0984C15.0568 17.939 15.273 17.8494 15.4984 17.8494C15.7239 17.8494 15.9401 17.939 16.0995 18.0984C16.2589 18.2578 16.3484 18.474 16.3484 18.6994V25.4994ZM19.3484 15.2994C19.3484 15.5248 19.2589 15.741 19.0995 15.9005C18.9401 16.0599 18.7239 16.1494 18.4984 16.1494C18.273 16.1494 18.0568 16.0599 17.8974 15.9005C17.738 15.741 17.6484 15.5248 17.6484 15.2994V7.49941C17.6484 7.27398 17.738 7.05778 17.8974 6.89837C18.0568 6.73897 18.273 6.64941 18.4984 6.64941C18.7239 6.64941 18.9401 6.73897 19.0995 6.89837C19.2589 7.05778 19.3484 7.27398 19.3484 7.49941V15.2994Z"
          fill="#F2F3FB"
        />
        <path
          d="M18.6977 16.3494C18.4722 16.3494 18.256 16.2599 18.0966 16.1005C17.9372 15.941 17.8477 15.7248 17.8477 15.4994C17.8477 15.274 17.9372 15.0578 18.0966 14.8984C18.256 14.739 18.4722 14.6494 18.6977 14.6494H25.4977C25.7231 14.6494 25.9393 14.739 26.0987 14.8984C26.2581 15.0578 26.3477 15.274 26.3477 15.4994C26.3477 15.7248 26.2581 15.941 26.0987 16.1005C25.9393 16.2599 25.7231 16.3494 25.4977 16.3494H18.6977Z"
          fill="#F2F3FB"
        />
      </svg>
    </div>
  );
};

export default CloseIcon;
