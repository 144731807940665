import React from "react";

function Word() {
  return (
    <svg width="20" height="24" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3105_44657)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M18.6408 1.632L17.1708 0H5.5944C4.7592 0 4.4364 0.6192 4.4364 1.1028V5.4588H6.06V1.9836C6.06 1.7988 6.216 1.6428 6.396 1.6428H14.6796C14.862 1.6428 14.9532 1.6752 14.9532 1.8252V7.6092H20.8488C21.0804 7.6092 21.1704 7.7292 21.1704 7.9044V22.0284C21.1704 22.3236 21.0504 22.368 20.8704 22.368H6.396C6.30674 22.3658 6.22182 22.3291 6.15913 22.2655C6.09645 22.2019 6.06091 22.1165 6.06 22.0272H4.45483C4.45245 22.1066 4.44737 22.9262 4.45483 23C4.51511 23.5962 4.89609 24 5.5944 24H21.672C22.512 24 22.7988 23.3916 22.7988 22.8372V6.2244L22.3788 5.7684L18.6408 1.632ZM17.0676 2.3448L16.6032 1.824V5.4804C16.6136 5.6524 16.6508 5.7776 16.7148 5.856C16.7788 5.936 16.9308 5.976 17.1708 5.976H20.3544L20.1828 5.7684L17.0676 2.3448Z"
          fill="#686DFC"
        />
        <path d="M20 11H16H15V12.5H20V11Z" fill="#686DFC" />
        <path d="M20 14H16H15V15.5H20V14Z" fill="#686DFC" />
        <path d="M20 17H16H15V18.5H20V17Z" fill="#686DFC" />
        <path d="M10.5 17.75L12 11H12.5L10.5 18H10L7.5 11.5H7L4.5 18H4L2 11H2.5L4 17.75H4.25L7 11H7.5L10.25 17.75H10.5Z" stroke="#686DFC" stroke-width="2" />
      </g>
      <defs>
        <clipPath id="clip0_3105_44657">
          <rect width="24" height="24" fill="#686DFC" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Word;
