import { Box, Button, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useAxios } from "../../../services/http.service";
import { fetchTokenAndPostChatbot } from "../../../services/http.chatbot.service";
import { useSelector } from "react-redux";
import SendIcon from "@mui/icons-material/Send";
import { useQuery, useQueryClient } from "react-query";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import LoaderIcon from "../../Icons/LoaderIcon";
import notificationService from "../../../services/notification.service";
import ChatWaitingIcon from "../../Icons/ChatWaitingIcon";
import ChatLoaderIcon from "../../Icons/ChatLoaderIcon";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'

const QueriesForm = ({ chatValue, chatHistory, setChatHistory }) => {
  const { user } = useSelector((state) => state.reducer);
  const [loader, setLoader] = useState(false);
  const [threadId, setThreadId] = useState("");
  const { matter } = useMultiFileUpload();
  const queryClient = useQueryClient();
;
  const { post, setBearerToken, get } = useAxios();
  const formik = useFormik({
    initialValues: {
      title: "",
    },
    // validationSchema: {}, // You can define a Yup validation schema here
    onSubmit: async (values, { resetForm }) => {
      if (!values.title) {
        notificationService.error("Error: The prompt cannot be empty. Please enter a valid value.");
        return;
      }
      setLoader(true);
      const apiData = {
        question: values.title,
        file: chatHistory ? chatHistory.files : chatValue?.items,
        thread_id: chatHistory ? chatHistory.thread_id : "",
        matter_id: chatHistory ? chatHistory.matter_id : "",
        sources: chatHistory?.source_number || chatValue?.sources || 4,
        assistant_id: "asst_arKWmrQDMlgTdbzWGPgR9nvM",
        organization: user?.organizationData?._id,
        api_key: process.env.CHATGTP_OPEN_API_KEY || "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
      };

      const externalApiResponse = await fetchTokenAndPostChatbot("/depositions/retrieve", apiData);

      // Debugging the response

      // Check if the external API call was successful
      if (externalApiResponse.status === 200) {
        const token = user?.userData?.token;
        setBearerToken(token);
        const data = {
          chatId: chatValue.items.id,
          question: values.title,
          answer: externalApiResponse.data.answer,
          moduleName: chatHistory ? chatHistory.moduleName : chatValue.category,
          title: externalApiResponse.data.file,
          files: chatHistory ? chatHistory.files : chatValue?.items,
          assistant_id: "asst_arKWmrQDMlgTdbzWGPgR9nvM",
          person_id: chatHistory ? chatHistory.person_id : chatValue?.personsId,
          source_number: chatHistory ? chatHistory.source_number : chatValue?.sources,
          matter_id: chatHistory ? chatHistory.matter_id : matter?._id,
          thread_id: chatHistory ? chatHistory.thread_id : externalApiResponse.data.thread_id,
          page: externalApiResponse.data.page,
          sources: externalApiResponse.data.sources,
        };
        const response = await post(`chatbot`, data);
        if (response) {
          setLoader(false);
          setChatHistory(data);
          resetForm();

          queryClient.invalidateQueries("thread");
          queryClient.invalidateQueries("chatbot");

          // notificationService.success("successful generate");
        }
      }
      resetForm(); // Reset the form after submission
    },
  });

  const [expanded, setExpanded] = React.useState(false);

  const handleExpansion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const fetchData = async () => {
    if (chatHistory) {
      let endpoint = `chatbot/${chatHistory?.thread_id}`;
      const token = user?.userData?.token;
      setBearerToken(token);
      const response = await get(endpoint);

      const thread = response?.data.find((v) => v.thread_id !== null && v.thread_id !== undefined);
      setThreadId(thread.thread_id);

      return response?.data;
    }
  };
  const { data, isLoading } = useQuery(["chatbot", chatHistory], fetchData, {});
  const chatContainerRef = useRef(null);

  // Scroll to the bottom of the chat when data changes
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [data]);
  function formatParagraphForMarkdown(paragraph) {
    // Step 1: Insert line breaks before each numbered list item (1., 2., etc.)
    let formattedParagraph = paragraph.replace(/(\d+)\.\s/g, '\n$1. ');
  
    // Step 2: Ensure there is a blank line before the list
    formattedParagraph = formattedParagraph.replace(/(\d+)\.\s/g, '\n\n$1. ');
  
    // Step 3: Ensure there is a blank line after the list
    formattedParagraph = formattedParagraph.replace(/(\d+\..+)(?!\n\n)/g, '$1\n\n');
  
    return formattedParagraph.trim(); // Trim any extra leading or trailing new lines
  }
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px", color: "lightgray", background: "#2B2C40", borderBottom: "1px solid #4A4C64" }}>
        <Box
          component="img"
          src="/images/Frame.png"
          alt="logo"
          sx={{
            width: "60px",
            height: "auto",
            p: 1,
          }}
        ></Box>
        <Typography variant="h6">Magic AI Chatbot</Typography>
      </Box>{" "}
      <Box sx={{ height: "55vh", overflow: "auto" }} ref={chatContainerRef}>
        {data?.map((v, i) => (
          <Box>
            <Box sx={{ display: "flex", alignItems: "center ", justifyContent: "end", gap: "10px", marginTop: "10px" }}>
              <Typography variant="body1" sx={{ color: "#A6A7C1", fontSize: "18px", fontWeight: "400", fontFamily: "Public Sans", background: "#2B2C40", p: 1, borderRadius: "4px", maxWidth: "80%" }}>
                {v.question}
              </Typography>
              <Typography
                sx={{
                  p: "7px",
                  backgroundColor: "#F2F3FB",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  color: "#2B2C40",
                  marginRight: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                  textTransform: "capitalize",
                }}
              >
                {`${user?.userData?.firstName?.charAt(0)}${user?.userData?.lastName?.charAt(0)}`}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
              <Box
                component="img"
                src="/images/Frame.png"
                alt="logo"
                sx={{
                  width: "60px",
                  height: "auto",
                  p: 1,
                }}
              ></Box>
                <Typography variant="body1" sx={{ color: "#F2F3FB", fontFamily: "Public Sans", maxWidth: "90%", fontSize: "16px" }}>
  <ReactMarkdown remarkPlugins={[remarkGfm]}> 
  {formatParagraphForMarkdown(v?.answer)}

      </ReactMarkdown> 
      </Typography>
{/* 
              <Typography variant="body1" sx={{ color: "#F2F3FB", fontFamily: "Public Sans", maxWidth: "90%", fontSize: "16px" }}>
              {v?.answer}
              </Typography> */}
            </Box>
            {v?.sources.map((val) => (
              <Box>
                <Accordion
                  expanded={expanded === val.source + i}
                  onChange={handleExpansion(val.source + i)}
                  sx={[
                    {
                      boxShadow: "none", // Remove the box shadow
                      borderBottom: "1px solid #4A4C64",

                      "&:before": {
                        display: "none",
                      },
                    },
                  ]}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{
                      background: expanded === val.source + i ? "#2B2C40" : "#242333",
                      color: "#F2F3FB",

                      "& .MuiSvgIcon-root": {
                        color: expanded === val.source + i ? "#686DFC" : "#F2F3FB",
                      },
                    }}
                  >
                    <Typography sx={{ color: expanded === val.source + i ? "#686DFC" : "#F2F3FB" }}>{val.source}</Typography>
                  </AccordionSummary>
                  <AccordionDetails sx={{ background: expanded === val.source + i ? "#2B2C40" : "#242333", color: "#F2F3FB" }}>
                    <Box sx={{ display: "flex", gap: "4px" }}>
                      <Typography variant="body1" sx={{ color: "#A6A7C1", fontFamily: "Public Sans", fontStyle: "italic" }}>
                        {" "}
                        Page:
                      </Typography>
                      <Typography variant="body1" sx={{ color: "#F2F3FB", fontFamily: "Public Sans", fontStyle: "italic" }}>
                        {val.page}
                      </Typography>
                    </Box>
                    {/* <Box sx={{ display: "flex", gap: "4px" }}>
                          <Typography variant="body1" sx={{ color: "#A6A7C1", fontFamily: "Public Sans", fontStyle: "italic" }}>
                            {" "}
                            Date:
                          </Typography>
                          <Typography variant="body1" sx={{ color: "#F2F3FB", fontFamily: "Public Sans", fontStyle: "italic" }}>
                            {moment(v.createdAt).format("D-MM-YYYY")}
                          </Typography>
                        </Box> */}

                    <Typography sx={{ fontStyle: "italic" }}>
                      <Typography dangerouslySetInnerHTML={{ __html: val.content.replace(/\n/g, "<br />") }} />
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            ))}
          </Box>
        ))}

        {loader && (
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
            <Box
              component="img"
              src="/images/Frame.png"
              alt="logo"
              sx={{
                width: "60px",
                height: "auto",
                p: 1,
              }}
            ></Box>
            <ChatWaitingIcon />
          </Box>
        )}
      </Box>
      <Box sx={{ color: "lightgray", background: "#242333", borderRadius: "1px", padding: "0 10px 40px 10px", marginTop: "20px" }} component="form" noValidate onSubmit={formik.handleSubmit}>
        <Grid item xs={12} sm={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end", // Align items at the bottom
              width: "100%",
              bgcolor: "#2B2C40",
              borderRadius: "5px",
              padding: "7px",
            }}
          >
            <TextField
              error={formik.touched.title && Boolean(formik.errors?.title)}
              autoComplete="given-title"
              name="title"
              id="title"
              placeholder="Type your message here..."
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.title}
              helperText={formik.touched?.title && formik.errors?.title}
              multiline
              minRows={1}
              maxRows={4}
              sx={{
                flexGrow: 1, // Ensures TextField takes up remaining space
                color: "#A6A7C1",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1",

                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2B2C40",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#2B2C40",
                  },
                },
                "& textarea": {
                  scrollbarWidth: "none", // Hide scrollbar for Firefox
                  "&::-webkit-scrollbar": {
                    display: "none", // Hide scrollbar for Chrome, Safari, and Edge
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: "#C8C9DB",
                },
              }}
              InputProps={{
                style: {
                  color: "#C8C9DB",
                },
                onKeyDown: (e) => {
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault(); // Prevent new line from being created
                    formik.handleSubmit(); // Submit the form or send the message
                  }
                },
              }}
            />
            <Button
              variant="contained"
              // size="medium"
              type="submit"
              sx={{
                marginRight: "9px",
                textTransform: "capitalize",
                backgroundColor: loader ? "#686DFC" : "#8A8BA1",
                color: "#ffff",
                borderRadius: "50%",
                p: "0",
                height: "40px", // Set equal height
                // width: "px",
                minWidth: "40px", // Prevent shrinking
                minHeight: "40px",
                "&:hover": {
                  backgroundColor: loader ? "#686DFC" : "#8A8BA1", // Keep blue background when hovered and loading
                },
                "&.Mui-disabled": {
                  backgroundColor: "#686DFC", // Custom disabled color
                  color: "#ffffff", // Text color when disabled
                },
              }}
            >
              {loader ? (
                <>
                  <ChatLoaderIcon />
                  {/* <LoaderIcon color="#f7f7f7" /> */}
                  {/* <SendIcon sx={{ width: "100%", height: "100%" }} /> */}
                </>
              ) : (
                <SendIcon />
              )}
            </Button>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

export default QueriesForm;
