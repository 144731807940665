import * as Yup from "yup";

export const AccountSchemeValidation = Yup.object().shape({
  firstName: Yup.string().min(3, "First name must be at least 3 characters").required("Required"),
  lastName: Yup.string().min(3, "Last name must be at least 3 characters").required("Required"),
  billingaddressone: Yup.string().min(10, "Address must be at least 10 characters").required("Required"),
  billingaddresstwo: Yup.string().min(10, "Address must be at least 10 characters"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
    .required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  secondaryemail: Yup.string()
    .email("Invalid email address")
    .test("unique-email", "Secondary email must be different from the primary email", function (value) {
      return value !== this.parent.email;
    }),
  zipcode: Yup.string()
    .matches(/^\d{5}$/, "Zip code must be exactly 5 digits")
    .required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string()
    .matches(/^[A-Za-z\s]+$/, "City must contain only letters and spaces")
    .required("Required"),
  role: Yup.string(),
  workPhone: Yup.string().matches(/^\d{10}$/, "Work phone number must be exactly 10 digits"),
});

export const PasswordSchemeValidation = Yup.object().shape({
  oldPassword: Yup.string().min(8, "The password must be at least 8 characters.").required("Required"),
  newPassword: Yup.string().min(8, "The password must be at least 8 characters.").required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword"), null], "The passwords you entered do not match.")
    .required("Please confirm your password."),
});

export const UserSchemeValidation = Yup.object().shape({
  firstName: Yup.string().min(3, "First name should be at least 3 characters. ").required("Required"),
  role: Yup.string().min(3).required("Required"),
  lastName: Yup.string().min(3, "Last name should be at least 3 characters.").required("Required"),
  email: Yup.string().email("Invalid Email address.").required("Required"),
});
export const initialValuesQueries = {
  category: "",
  items: "",
  chatId: "",
  sources: "",
  personsId: "",
};
export const QueriesSchemeValidation = Yup.object().shape({
  category: Yup.string(),
  items: Yup.string(),
});
