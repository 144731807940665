import { Box, Divider, IconButton, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteModel from "../Modal/DeleteModel";
import { useNavigate } from "react-router-dom";
import useFileActions from "../../hooks/useFileActions";
import { Delete, LockReset, RemoveRedEye } from "@mui/icons-material";
import { BiSolidEditAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import GetAppIcon from "@mui/icons-material/GetApp";

const TableDropdown = ({ data, viewPageUrl, resetPassword, resetPasswordUrl, deleteUrl, isDelete, isDocument, editPageUrl, isDownload ,onClickDownload}) => {
  const navigate = useNavigate();
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { handleFileAction } = useFileActions();
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpen = () => {
    setOpen(true);
    handleCloseUserMenu();
  };
  const [open, setOpen] = React.useState(false);

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <IconButton onClick={handleOpenUserMenu}>
          <MoreVertIcon sx={{ color: "lightgray" }} />
        </IconButton>
      </Tooltip>
      <Menu
        sx={{
          mt: "30px",
          color: "white", // Set text color to white
          "& .MuiPaper-root": {
            // Ensuring the dropdown has the correct background color
            backgroundColor: "#4A4C64",
          },

          direction: "flex",
          flexDirection: "column",
        }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem sx={{ color: "#F2F3FB" }} onClick={() => navigate(editPageUrl, { state: { userData: data } })}>
          <BiSolidEditAlt sx={{ color: "#F2F3FB", fontSize: "22px" }} size={20} />
          <Typography
            color={"#F2F3FB"}
            sx={{
              width: "100%",
              paddingY: "2px",
              paddingX: "5px",
            }}
          >
            Edit Record
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => navigate(viewPageUrl, { state: { viewRecord: data } })}>
          <RemoveRedEye sx={{ color: "#F2F3FB", fontSize: "18px" }} size={20} />
          <Typography
            onClick={() => navigate(viewPageUrl, { state: { viewRecord: data } })}
            color={"#F2F3FB"}
            sx={{
              width: "100%",
              paddingY: "2px",
              paddingX: "5px",
            }}
          >
            View Record
          </Typography>
        </MenuItem>
        {resetPassword && (
          <>
            <MenuItem>
              <LockReset sx={{ color: "#F2F3FB", fontSize: "22px" }} size={20} />
              <Typography
                onClick={() => navigate(resetPasswordUrl, { state: { viewRecord: data } })}
                color={"#F2F3FB"}
                sx={{
                  width: "100%",
                  paddingY: "2px",
                  paddingX: "5px",
                }}
              >
                Reset Password
              </Typography>
            </MenuItem>
          </>
        )}

        {isDelete && (
          <>
            <MenuItem onClick={handleOpen}>
              <DeleteIcon sx={{ color: "#DC3545", fontSize: "22px" }} size={20} />
              <Typography
                color={"#DC3545"}
                sx={{
                  width: "100%",
                  paddingY: "2px",
                  paddingX: "5px",
                }}
              >
                Delete Record
              </Typography>
            </MenuItem>
          </>
        )}
        {isDownload && (
          <MenuItem>
            <GetAppIcon sx={{ color: "#F2F3FB", fontSize: "22px" }} size={20} />
            <Typography
            onClick={() => onClickDownload(data)}
              color={"#F2F3FB"}
              sx={{
                width: "100%",
                paddingY: "2px",
                paddingX: "5px",
              }}
            >
              Download
            </Typography>
          </MenuItem>
        )}

        <DeleteModel data={data} deleteUrl={deleteUrl} setOpen={setOpen} open={open} />
      </Menu>
    </Box>
  );
};

export default TableDropdown;
