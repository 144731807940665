import React from "react";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Typography } from "@mui/material";

const ConfirmationModel = ({ handleLogoutConfirm, onClose }) => {
  return (
    <Box onClose={onClose}>
      <Grid container spacing={{ xs: 0, sm: 1 }}>
        <Grid item xs={11} sm={11}>
          <Typography variant="h4" sx={{ color: "#E6E7F2", fontFamily: "Public Sans ,sans-serif", textAlign: "center", marginLeft: "15px" }}>
            Are you sure?
          </Typography>
        </Grid>

        <Grid item xs={12} sm={12} sx={{ textAlign: "center" }}>
          <Typography variant="p" sx={{ color: "#E6E7F2", fontFamily: "Public Sans ,sans-serif" }}>
            Are you sure you want to log out?{" "}
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            marginTop: 2,
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            gap: 1,
          }}
        >
          <Button
            onClick={handleLogoutConfirm}
            variant="contained"
            sx={{
              width: { xs: "100%", sm: "auto" },
              mt: { xs: 0, sm: 1.5 },
              mb: { xs: 0, sm: 2 },
              boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
              "&:hover, &:focus": {
                boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                backgroundColor: "white",
                color: "#6a1b9a",
              },
              fontSize: "12px",
              backgroundColor: "#686DFC",
              fontWeight: "600",
              fontFamily: "Public Sans ,sans-serif",
            }}
          >
            Yes{" "}
          </Button>
          <Button
            onClick={onClose}
            variant="contained"
            sx={{
              bottom: "0px", // Adjust as needed
              width: { xs: "100%", sm: "auto" },
              mt: { xs: 1, sm: 1.5 },
              mb: { xs: 0, sm: 2 },
              boxShadow: "0px 4px 8px #4A4C64",
              "&:hover, &:focus": {
                boxShadow: "0px 6px 12px #4A4C64",
                backgroundColor: "darkgray",
              },
              "&:disabled": {
                backgroundColor: "#4A4C64",
                // color: "white",
                boxShadow: "0px 4px 8px #4A4C64",
              },
              fontSize: "12px",
              backgroundColor: "#4A4C64",
              fontWeight: "600",
              fontFamily: "Public Sans ,sans-serif",
            }}
          >
            No{" "}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfirmationModel;
