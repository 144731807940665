import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, TextField, Button, Autocomplete, InputAdornment, FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../services/http.service";
const UpdateRoleForm = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state?.reducer?.user);
  const location = useLocation();
  const { userData } = location.state || {};
   const queryClient = useQueryClient();
  const { setBearerToken, put } = useAxios();
  const [permissionData, setPermissionData] = useState([]);
   //   const permissionData = userData;
  const { token } = useSelector((state) => state?.reducer?.user?.userData);

  const setClientData = async (newData) => {
    setBearerToken(token);
    const response = await put(`/roles`, newData);
     return response.data;
  };

  const mutation = useMutation(setClientData, {
    onSuccess: () => {
      showSuccessToastMessage("Record is successfull updated!");
      navigate("/role");
    },
    onError: () => {
      showErrorToastMessage("Something was wrong!");
    },
  });
  const formik = useFormik({
    initialValues: {
      roleId: userData?.roleId,
      name: userData?.name,
      description: userData?.description,
      permissionId: userData?.permission,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      description: Yup.string(),
      permissionId: Yup.string().required("Required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      mutation.mutate(values);
    },
  });
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const getAllPermission = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "permissions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setPermissionData(response?.data?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllPermission();
  }, []);

  return (
    <Box
      sx={{
        // minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
      }}
    >
      <CssBaseline />
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={formik.touched.name && formik.errors?.name}
              autoComplete="given-name"
              name="name"
              required
              id="name"
              label="Role name"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              helperText={formik.touched?.name && formik.errors?.name}
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.name && Boolean(formik.errors.name) ? "#D32F2F" : "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.name && Boolean(formik.errors.name) ? "#D32F2F" : "#C8C9DB",
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: formik.touched?.name && Boolean(formik.errors?.name) ? "#D32F2F" : "#C8C9DB",
                },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
            <FormControl
              required
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched.permissionId && Boolean(formik.errors.permissionId)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.permissionId && Boolean(formik.errors.permissionId) ? "lightmaroon" : "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.permissionId && Boolean(formik.errors.permissionId) ? "lightmaroon" : "#C8C9DB",
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <InputLabel
                id="permission-label"
                sx={{
                  color: "#C8C9DB",
                  // Change label color
                  "&.Mui-focused": {
                    color: "#A6A7C1", // Change label color when focused
                  },
                }}
              >
                Permission
              </InputLabel>
              <Select
                labelId="permissionId-label"
                id="demo-simple-select"
                autoComplete="off"
                name="permissionId"
                variant="outlined"
                size="small"
                label="permissionId"
                value={formik.values.permissionId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                // IconComponent={KeyboardArrowDownIcon}
              >
                {permissionData?.map((row) => {
                  return (
                    <MenuItem value={row?._id} key={row?._id}>
                      {row?.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {permissionData?.length <= 0 && <FormHelperText sx={{ color: "#D32F2F" }}>No Permission are added.</FormHelperText>}
              {formik.touched.permissionId && formik.errors.permissionId && <FormHelperText>{formik.errors.permissionId}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={6}
              name="description"
              variant="outlined"
              value={formik.values?.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched?.description && Boolean(formik.errors?.description)}
              helperText={formik.touched?.description && formik.errors?.description}
              sx={{
                width: "100%",
                borderRadius: "5px",
                mb: "20px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray", // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A6A7C1", // Yellow border color on focus
                  },
                },
              }}
              InputLabelProps={{
                style: { color: "#C8C9DB" },
              }}
              InputProps={{
                style: {
                  // height: "200px",
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          '{" "}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row-reverse" },
              gap: 1,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              // disabled={isSubmitting}
              // disabled={Object.keys(formik?.errors).length > 0}
              // disabled={isSubmitButtonDisabled}
              sx={{
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 0, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                "&:disabled": {
                  backgroundColor: "#4A4C64",
                  color: "white",
                  boxShadow: "0px 4px 8px #4A4C64",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Submit
            </Button>

            <Button
              onClick={() => navigate("/role")}
              variant="contained"
              sx={{
                // position: "absolute",
                bottom: "0px", // Adjust as needed
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px #4A4C64",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px #4A4C64",
                  backgroundColor: "darkgray",
                  // color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#4A4C64",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateRoleForm;
