import React, { useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IssuesIcon from "../Components/Icons/IssuesIcon";
import ClientsIcon from "../Components/Icons/ClientsIcon";
import CasesIcon from "../Components/Icons/CasesIcon";
import { PleadingIcon } from "../Components/Icons/PleadingIcon";
import CorespondeceIcon from "../Components/Icons/CorespondeceIcon";
import DispositionsIcon from "../Components/Icons/DispositionsIcon";
import ExibitsIcon from "../Components/Icons/ExibitsIcon";
import MedicalIcon from "../Components/Icons/MedicalIcon";
import PersonsIcons from "../Components/Icons/PersonsIcons";
import DiscoverIcon from "../Components/Icons/DiscoverIcon";
import { useNavigate, useLocation, Link } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import CourtIcon from "../Components/Icons/CourtIcon";
import { useMultiFileUpload } from "../context/useMultiFileUploade";
import CircleIcon from "../Components/Icons/CircleIcon";

const NavItem = ({ path, icon: Icon, text, isDrawOpen, subHeading }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActive = location.pathname === path;

  return (
    <ListItemButton
      onClick={() => navigate(path)}
      sx={{
        borderRight: isActive && isDrawOpen ? "4px solid" : "none",
        borderColor: isActive && isDrawOpen ? "#686DFC" : "transparent",
        color: "lightgray",
        height: "40px",
        // borderRadius: "2px",
      }}
    >
      <ListItemIcon
        sx={{
          backgroundColor: isActive ? "#686DFC" : "none",
          paddingBlock: "8px",
          paddingLeft: "8px",
          borderTopLeftRadius: "6px",
          borderBottomLeftRadius: "6px",
          textAlign: "start",
        }}
      >
        <Icon colors={isActive ? "#FFFFFF" : "#A6A7C1"} />
      </ListItemIcon>
      <ListItemText
        primary={text}
        sx={{
          display: isDrawOpen ? "block" : "none",
          backgroundColor: isActive ? "#686DFC" : isDrawOpen ? "none" : "none",
          color: isActive ? "#FFFFFF" : "#A6A7C1",
          paddingBlock: "8px",
          borderTopRightRadius: "6px",
          borderBottomRightRadius: "6px",
        }}
      />
    </ListItemButton>
  );
};

export const MainListItems = () => {
  const { openDropdown, setOpenDropdown } = useMultiFileUpload();
  // Handlers for dropdowns
  const handleDropdown = (dropdown) => {
    setOpenDropdown((prevState) => (prevState === dropdown ? null : dropdown)); // Toggle dropdown
  };
  const isDrawOpen = true;

  return (
    <>
      <Box sx={{ margin: "10px 0px 10px 0px" }}>
        <Box
          onClick={() => handleDropdown("database")} // Open "Database Entry" dropdown
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: isDrawOpen ? "space-between" : "center",
            color: "#C8C9DB",
            margin: `15px 10px 15px ${isDrawOpen ? "0px" : "15px"}`,
            cursor: "pointer",
          }}
        >
          {isDrawOpen && (
            <>
              <RemoveIcon sx={{ color: openDropdown === "database" ? "#686DFC" : "#C8C9DB" }} />
              <Typography sx={{ marginLeft: "-20%", color: openDropdown === "database" ? "#686DFC" : "#C8C9DB" }}>Database Entry</Typography>
            </>
          )}
          {openDropdown === "database" ? <KeyboardArrowDownIcon sx={{ color: isDrawOpen ? "#686DFC" : "#C8C9DB" }} /> : <ChevronRightIcon sx={{ color: isDrawOpen ? "#C8C9DB" : "#686DFC" }} />}
        </Box>
      </Box>
      {openDropdown === "database" && (
        <>
          <NavItem path="/issues" icon={IssuesIcon} text="Issues" isDrawOpen={isDrawOpen} />
          <NavItem path="/clients" icon={ClientsIcon} text="Client" isDrawOpen={isDrawOpen} />
          <NavItem path="/courts" icon={CourtIcon} text="Courts" isDrawOpen={isDrawOpen} />
          <NavItem path="/matters" icon={CasesIcon} text="Matters" isDrawOpen={isDrawOpen} />
          <NavItem path="/persons" icon={PersonsIcons} text="Persons" isDrawOpen={isDrawOpen} />
          <NavItem path="/medical" icon={MedicalIcon} text="Medical Records" isDrawOpen={isDrawOpen} />
          <NavItem path="/exhibits" icon={ExibitsIcon} text="Exhibits" isDrawOpen={isDrawOpen} />
          <NavItem path="/discovery" icon={DiscoverIcon} text="Discovery" isDrawOpen={isDrawOpen} />
          <NavItem path="/depositions" icon={DispositionsIcon} text="Depositions" isDrawOpen={isDrawOpen} />
          <NavItem path="/correspondence" icon={CorespondeceIcon} text="Correspondence" isDrawOpen={isDrawOpen} />
          <NavItem path="/pleadings" icon={PleadingIcon} text="Pleadings" isDrawOpen={isDrawOpen} />
        </>
      )}
      <Box component="div" sx={{ borderTop: "1px solid #4A4C64", margin: "10px 0px 10px 0px" }}>
        <Box
          onClick={() => handleDropdown("document")} // Open "Document Generation" dropdown
          component="div"
          sx={{
            display: "flex",
            justifyContent: isDrawOpen ? "space-between" : "center",
            color: "#C8C9DB",
            margin: `15px 10px 15px ${isDrawOpen ? "0px" : "15px"}`,
            cursor: "pointer",
          }}
        >
          {isDrawOpen && (
            <>
              {" "}
              <RemoveIcon sx={{ color: openDropdown === "document" ? "#686DFC" : "#C8C9DB" }} />
              <Typography sx={{ color: openDropdown === "document" ? "#686DFC" : "#C8C9DB" }}>Document Generation</Typography>
            </>
          )}
          {openDropdown === "document" ? <KeyboardArrowDownIcon sx={{ color: isDrawOpen ? "#686DFC" : "#C8C9DB" }} /> : <ChevronRightIcon sx={{ color: isDrawOpen ? "#C8C9DB" : "#686DFC" }} />}
        </Box>
      </Box>
      {openDropdown === "document" && (
        <>
          <NavItem path="/Pleading" icon={PleadingIcon} text="Pleadings" isDrawOpen={isDrawOpen} isOpen={openDropdown === "pleading"} />

          <Link to="/Pleading" style={{ textDecoration: "none" }}>
            {" "}
            <Box sx={{ display: "flex", alignItems: "center", gap: "30px", px: "16px", py: "10px" }}>
              <CircleIcon />
              <Typography sx={{ color: openDropdown === "document" ? "lightgrey" : "#F2F3FB", textDecoration: "none" }}>
                Statement of Material <br /> Facts
              </Typography>
            </Box>
          </Link>
        </>
      )}
      <Link to="/queries" style={{ textDecoration: "none" }}>
        {" "}
        <Box component="div" sx={{ borderTop: "1px solid #4A4C64", borderBottom: "1px solid #4A4C64", margin: "10px 0px 10px 0px" }}>
          <Box
            component="div"
            sx={{
              display: "flex",
              justifyContent: isDrawOpen ? "space-between" : "center",
              color: "#C8C9DB",
              margin: `15px 10px 15px ${isDrawOpen ? "0px" : "15px"}`,
              cursor: "pointer",
            }}
          >
            {isDrawOpen && (
              <>
                <Box sx={{ display: "flex", gap: "10px" }} onClick={() => handleDropdown("queries")}>
                  <RemoveIcon sx={{ color: openDropdown === "queries" ? "#686DFC" : "#C8C9DB" }} />

                  <Typography
                    sx={{
                      color: openDropdown === "queries" ? "#686DFC" : "#C8C9DB", // Highlight when active
                      textDecoration: "none", // Underline when active
                      cursor: "pointer", // Show pointer to indicate it's clickable
                    }}
                  >
                    Queries{" "}
                  </Typography>
                </Box>
              </>
            )}
            {/* {Document ? <KeyboardArrowDownIcon sx={{ color: isDrawOpen ? "#686DFC" : "#C8C9DB" }} /> : <ChevronRightIcon sx={{ color: isDrawOpen ? "#686DFC" : "#C8C9DB" }} />}{" "} */}
            {/* <ChevronRightIcon sx={{ color: "#C8C9DB" }} /> */}
          </Box>
        </Box>{" "}
      </Link>
    </>
  );
};
