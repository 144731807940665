import React from 'react'

const PersonsIcons = ({colors}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={colors}
      d="M22 13.833c0 .5-1 .834-2.167 1-.75-1.416-2.25-2.5-4-3.25.167-.25.334-.416.5-.666H17c2.583-.084 5 1.5 5 2.916zm-14.333-3H7c-2.583 0-5 1.584-5 3 0 .5 1 .834 2.167 1 .75-1.416 2.25-2.5 4-3.25l-.5-.75zm4.333.834c1.833 0 3.333-1.5 3.333-3.334C15.333 6.5 13.833 5 12 5a3.343 3.343 0 00-3.333 3.333c0 1.834 1.5 3.334 3.333 3.334zm0 .833c-3.417 0-6.667 2.167-6.667 4.167 0 1.666 6.667 1.666 6.667 1.666s6.667 0 6.667-1.666c0-2-3.25-4.167-6.667-4.167zm4.75-2.5H17c1.417 0 2.5-1.083 2.5-2.5S18.417 5 17 5c-.417 0-.75.083-1.083.25A4.951 4.951 0 0117 8.333c0 .584-.083 1.167-.25 1.667zM7 10h.25A5.373 5.373 0 017 8.333c0-1.166.417-2.25 1.083-3.083A2.307 2.307 0 007 5C5.583 5 4.5 6.083 4.5 7.5S5.583 10 7 10z"
    ></path>
  </svg>
  )
}

export default PersonsIcons
