import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Paper, Typography } from "@mui/material";
import UpdateCourtForm from "../../Components/Forms/CourtForm/UpdateCourtForm.js";
import UpdatePermissionForm from "../../Components/Forms/RoleForm/UpdatePermission.js";
import UpdateRoleForm from "../../Components/Forms/RoleForm/UpdateRole.js";

const EditRolePage = () => {
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: "lightgray" }}>
              Role /
              <Box component="span" sx={{ color: "white" }}>
                {" "}
                Edit Role
              </Box>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{
                p: 2,
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#2B2C40",
                color: "lightgray",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <UpdateRoleForm />
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default EditRolePage;
