import React, { useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Divider, Grid, Paper, Typography, withStyles } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import BalanceIcon from "@mui/icons-material/Balance";
import FeedIcon from "../../Components/Icons/FeedIcon";
import RoleForm from "../../Components/Forms/RoleForm";

const steps = [{ text: "Basic Details", label: "Issue information" }];
const AddNewRolePage = () => {
  const [activeStep, setActiveStep] = React.useState(3);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" sx={{ color: "lightgray" }}>
              Role /
              <Box component="span" sx={{ color: "white" }}>
                {" "}
                Add New Role
              </Box>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#2B2C40",
                color: "lightgray",
                padding: "20px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Stepper
                    activeStep={activeStep}
                    connector={
                      <ArrowForwardIosIcon
                        sx={{
                          marginLeft: "20px",
                          marginRight: "5px",
                        }}
                      />
                    }
                  >
                    {steps.map((step, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      labelProps.optional = (
                        <Typography
                          variant="caption"
                          sx={{
                            color: "#8A8BA1",
                            fontFamily: "sans-serif",
                            fontSize: "14px",
                          }}
                        >
                          {step.label}
                        </Typography>
                      );
                      if (isStepSkipped(index)) {
                        stepProps.completed = false;
                      }
                      return (
                        <Step
                          key={index}
                          {...stepProps}
                          sx={{
                            "& .MuiStepLabel-root .Mui-completed": {
                              color: "white",
                            },
                          }}
                        >
                          <StepLabel
                            {...labelProps}
                            icon={
                              step.text == "Basic Details" ? (
                                <Box
                                  component="span"
                                  sx={{
                                    borderRadius: "10px",
                                    padding: "8px 10px",
                                    backgroundColor: activeStep >= index ? "#686DFC" : "#4A4C64",
                                  }}
                                >
                                  <FeedIcon color="white" />
                                </Box>
                              ) : (
                                <Box
                                  component="div"
                                  sx={{
                                    borderRadius: "10px",
                                    padding: "8px 10px",
                                    "& .Mui-active": { backgroundColor: "red" },
                                    backgroundColor: activeStep >= index ? "#686DFC" : "#4A4C64",
                                  }}
                                >
                                  <BalanceIcon />
                                </Box>
                              )
                            }
                          >
                            {step.text}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  <Grid item xs={12}>
                    <Divider sx={{ backgroundColor: "#4A4C64", marginY: "5px" }} />
                  </Grid>

                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography sx={{ mt: 2, mb: 1 }}>All steps completed - you&apos;re finished</Typography>
                      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <RoleForm />
                    </React.Fragment>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default AddNewRolePage;
