import React, { createContext, useContext, useState } from "react";
import { CompleteMultipartUploadCommand, CreateMultipartUploadCommand, S3Client, UploadPartCommand } from "@aws-sdk/client-s3";
import { useAxios } from "../services/http.service";
import { fetchTokenAndPostChatbot } from "../services/http.chatbot.service";
import axios from "axios";
import notificationService from "../services/notification.service";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";

const CHUNK_SIZE = 5 * 1024 * 1024;

const MultiFileUploadContext = createContext(null);

export const useMultiFileUpload = () => {
  const context = useContext(MultiFileUploadContext);
  if (context === null) {
    throw new Error("useMultiFileUpload must be used within a MultiFileUploadProvider");
  }
  return context;
};

export const MultiFileUploadProvider = ({ children, token }) => {
  const [uploads, setUploads] = useState([]);
  const [isGenerated, setIsGenerated] = useState(false);
  const [matter, setMatter] = useState();
  const [openDropdown, setOpenDropdown] = useState("database");
  const { user } = useSelector((state) => state.reducer);
  const [isView, setIsView] = useState(false);
  const { setBearerToken, post } = useAxios();
  const queryClient = useQueryClient();

  const s3 = new S3Client({
    region: "us-west-2",
    credentials: {
      accessKeyId: "AKIA3XJEGJY3OKEC5RRL",
      secretAccessKey: "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",
    },
  });

  const uploadFiles = async ({ id, title, file, url, uniquePath }) => {
    try {
      const existingUploadIndex = uploads.findIndex((upload) => upload.id === id);

      if (existingUploadIndex !== -1) {
        console.warn("File with same ID is already being uploaded");
        return;
      }

      setUploads((prevUploads) => [...prevUploads, { id, title, progress: 0, file, uniquePath }]);

      const params = {
        Bucket: "magicpleadings.ai",
        Key: uniquePath,
        ContentType: file.type,
      };

      const data = await s3.send(new CreateMultipartUploadCommand(params));
      const uploadId = data.UploadId;
      let totalUploaded = 0;
      const parts = [];

      for (let i = 0, partNumber = 1; i < file.size; i += CHUNK_SIZE, partNumber++) {
        const chunk = file.slice(i, CHUNK_SIZE + i);
        const partParams = {
          Bucket: "magicpleadings.ai",
          Key: uniquePath,
          PartNumber: partNumber,
          UploadId: uploadId,
          Body: chunk,
        };

        const res = await s3.send(new UploadPartCommand(partParams));
        totalUploaded += chunk.size;

        const progress = (totalUploaded / file.size) * 100;
        parts.push({ ETag: res.ETag, PartNumber: partNumber });

        setUploads((prevUploads) => {
          return prevUploads.map((upload) => (upload.id === id ? { ...upload, progress: Math.round(progress) } : upload));
        });
      }

      const completeParams = {
        Bucket: "magicpleadings.ai",
        Key: uniquePath,
        UploadId: uploadId,
        MultipartUpload: { Parts: parts },
      };

      await s3.send(new CompleteMultipartUploadCommand(completeParams));
      await succesStatus(url, id);
    } catch (error) {
      console.error("Error uploading file:", error);
    }
  };
  const succesStatus = async (url, id) => {
    setBearerToken(token);
    setUploads((prevUploads) => prevUploads.filter((upload) => upload.id !== id));
    const response = await post(url, { id });
    if (response && response.data) {
      if (url === "status/deposition") {
        const apiData = {
          file: response.data.DipositionRecord.fileUrl,
          organization: user?.organizationData?._id,
          matter_id:response.data.DipositionRecord.case[0],
          api_key: process.env.CHATGTP_OPEN_API_KEY || "sk-rVLvFpg5-Fdc6Q3p2oSzHsOVfixz2XHa0Vm-j_77oqT3BlbkFJG--HBmDoS18CUIXsz5dbEhim-O_4lFMTewxllN_YQA",
        };

 
        // Call the external API
        const externalApiResponse = await fetchTokenAndPostChatbot("/depositions/add", apiData);

        // Debugging the response
 
        // Check if the external API call was successful
        if (externalApiResponse.status === 200) {
         }
      }
    }

    return response.data;
  };

  const generateDocument = async (data) => {
    try {
      const { id, title, formData } = data;
  
      const config = {
        onUploadProgress: (progressEvent) => {
          setIsGenerated(true);
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          updateUploadProgress(id, percentCompleted, title);
        },
      };
  
      const response = await axios.post(`https://api.soon.magicpleadings.ai/make/process-pdf`, formData, config);
  
      if (response.data) {
        // If response contains data, update the upload status to "success"
        updateUploadStatus(id, "success", response.data, title);
  
        const deepCopy = JSON.parse(JSON.stringify(response.data));
  
        const generatedData = {
          id: id,
          docx_file_url: response?.data?.docx_file_url,
          excel_file_url: response?.data?.excel_file_url,
          status: 'generated',
        };
  
        try {
          const res = await post("/status/pleadingGenerate", generatedData);
  
          if (res && res.data) {
            if (deepCopy === "Accepted") {
              notificationService.error("File generation failed.");
            } else {
              notificationService.success("Files have been generated successfully.");
            }
  
            setIsView(false);
            setIsGenerated(false);
            queryClient.invalidateQueries("pleadingGenerate");
          } else {
            // If the API call fails, update the status to "failed"
            await post("/status/pleadingGenerate", {
              id: id,
              status: 'failed',
            });
            notificationService.error("File generation failed.");
            setIsGenerated(false);
          }
        } catch (error) {
          console.error("Error during API call:", error);
          setIsGenerated(false);
          notificationService.error("Error generating file.");
          // Send "failed" status to the API if an error occurs
          await post("/status/pleadingGenerate", {
            id: id,
            status: 'failed',
          });
        }
      } else {
        // If no data in the response, send failed status
        console.error("No data in response.");
        await post("/status/pleadingGenerate", {
          id: id,
          status: 'failed',
        });
        setIsGenerated(false);
        notificationService.error("Missing file1 or file2 in response data.");
      }
  
      return response.data;
    } catch (error) {
      console.error("Error generating document:", error);
      // Send "failed" status if the whole process fails
      await post("/status/pleadingGenerate", {
        id: data.id,
        status: 'failed',
      });
      notificationService.error("File generation failed.");
      setIsGenerated(false);
      throw error;
    }
  };
  

  const updateUploadProgress = (id, progress, title) => {
    setUploads((prevUploads) => prevUploads.map((upload) => (upload.id === id ? { ...upload, progress, title } : upload)));
  };

  const updateUploadStatus = (id, status, data = null, title) => {
    setUploads((prevUploads) => prevUploads.map((upload) => (upload.id === id ? { ...upload, status, data, title } : upload)));
  };

  return (
    <MultiFileUploadContext.Provider
      value={{
        uploads,
        setUploads,
        uploadFiles,
        generateDocument,
        matter,
        setMatter,
        openDropdown,
        setOpenDropdown,
        isGenerated,
      }}
    >
      {children}
    </MultiFileUploadContext.Provider>
  );
};
