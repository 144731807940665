import React, { useState } from "react";
import { Search, SearchIconWrapper, StyledInputBase } from "../../../pages/Queries/Style";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, Menu, MenuItem, TextField, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BiFontSize, BiSolidEditAlt } from "react-icons/bi";
import DeleteIcon from "@mui/icons-material/Delete";
import { useAxios } from "../../../services/http.service";
import { useSelector } from "react-redux";
import PopupModel from "../../../Components/Modal";
import { useQuery, useQueryClient } from "react-query";
import QueriesConfirmationModel from "./QueriesDelete";

import CheckIcon from "@mui/icons-material/Check";
import notificationService from "../../../services/notification.service";
import axios from "axios";
const HistoryForm = ({ chatHistory, setChatHistory }) => {
  const [threadData, setThreadData] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const queryClient = useQueryClient();
  const { get, setBearerToken } = useAxios();
  const open = Boolean(anchorEl);
  const fetchData = async () => {
    let endpoint = `/getThread`;
    setBearerToken(token);
    const response = await get(endpoint);
    return response?.data;
  };

  const { data, isLoading } = useQuery(["thread"], fetchData, {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleHistoryData = (v) => {
    setChatHistory(v);
    queryClient.invalidateQueries("chatbot");
  };

  const [editingId, setEditingId] = useState(null); // Track the ID being edited
  const [renameValue, setRenameValue] = useState(""); // Track the input value
  const [openQueries, setOpenQueries] = useState(false);

  const handleOpen = (v) => {
    setOpenQueries(true);
    setThreadData(v);
  };
  const handleCloseQueries = () => {
    setOpenQueries(false);
    handleClose();
  };
  const handleRenameClick = (v) => {
    setEditingId(v._id); // Set the current editing thread
    setRenameValue(v.answer); // Set the current answer as the initial value
  };

  // Handle input change in the TextField
  const handleInputChange = (e) => {
    setRenameValue(e.target.value);
  };

  // Handle saving the renamed value (e.g., on pressing Enter)
  const handleRenameSave = (v) => {
    // Update the data in place or through a function that updates state
    // Reset editing state
    setEditingId(null);
  };

  const handleCheckClick = async (v) => {
    try {
      // Log the current object
      // Prepare the data to be sent to the API
      const data = {
        thread_id: v.thread_id,
        title: renameValue, // Assuming renameValue holds the new title
      };

      // Send a PUT request to the server with the updated thread title
      const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}thread/update-title`, data);

      // Check if the request was successful
      if (response.status === 200) {
        // Invalidate the query to refresh the thread data
        queryClient.invalidateQueries("thread");

        // Show a success notification
        notificationService.success("Thread title has been updated successfully.");
      } else {
        console.error("Failed to update thread title");
        notificationService.error("Failed to update thread title.");
      }
    } catch (error) {
      // Handle errors during the API request
      console.error("Error updating thread title:", error);
      notificationService.error("Failed to update the thread title.");
    }

    // Reset the state
    setEditingId(null); // Stop editing after save
    setRenameValue(""); // Clear the input field after saving
  };

  return (
    <Box sx={{ p: 2 }}>
      <PopupModel
        open={openQueries}
        styleProp={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          // width: { xs: "90%", sm: "auto" },
          width: { xs: "90%", sm: "500px" }, // Set a fixed width
          maxWidth: "100%", // Ensure it does not exceed screen size
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          height: "auto",
          backgroundColor: "#262535",
          boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
          border: "1px solid #686DFC",
        }}
        handleClose={handleCloseQueries}
        cards={<QueriesConfirmationModel closeModal={handleCloseQueries} threadData={threadData} />}
      />
      <Typography sx={{ color: "#686DFC", fontFamily: "Public Sans", mt: "20px" }}>Recent</Typography>
      {data?.map((v) => (
        <Box
          key={v.thread_id} // Make sure to provide a unique key
          sx={{
            display: "flex",
            cursor: "pointer",
            justifyContent: "space-between",
            alignItems: "center",
            color: "#FFFFF",
            fontSize: "18px",
            background: chatHistory?.thread_id == v?.thread_id ? "#686DFC" : "none",
            borderRadius: "6px",
            // padding: "3px",
            marginTop: "5px",
          }}
          onClick={() => {
            if (editingId !== v._id) {
              setEditingId(null);
            }
            if (!anchorEl) {
              handleHistoryData(v); // Handle history data for each item
            }
          }}
        >
          {editingId === v._id ? (
            <>
              <TextField
                value={renameValue}
                onChange={handleInputChange}
                size="small"
                variant="outlined"
                sx={{
                  maxWidth: "220px",
                  color: "#FFFFFF",
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover fieldset": {
                      borderColor: "#FFFFFF",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#FFFFFF",
                    },
                    "& input": {
                      color: "#FFFFFF",
                    },
                  },
                }}
              />
              <IconButton onClick={() => handleCheckClick(v)}>
                <CheckIcon style={{ color: "green", fontSize: 30 }} />
              </IconButton>
            </>
          ) : (
            <>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Public Sans",
                  whiteSpace: "nowrap",
                  overflow: "hidden",

                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                  paddingLeft: "10px",
                }}
              >
                {v.title}
              </Typography>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation(); // Prevents clicking this from affecting the parent
                  setAnchorEl(e.currentTarget); // Set anchor element for this specific item
                  setSelectedItem(v); // Store the current item in state
                }}
              >
                <MoreVertIcon sx={{ color: "#FFFFFF" }} />
              </IconButton>
            </>
          )}

          {/* Menu Dropdown */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                backgroundColor: "#4A4C64",
                color: "#C8C9DB",
                "& .MuiMenuItem-root": {
                  "&:hover": {
                    backgroundColor: "#686DFC26",
                    color: "#686DFC",
                  },
                },
              },
            }}
          >
            <MenuItem
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation to avoid affecting the parent
                handleRenameClick(selectedItem); // Use the selected item for rename
                handleClose(); // Close the menu
              }}
            >
              <BiSolidEditAlt style={{ cursor: "pointer" }} />
              <Typography>Rename</Typography>
            </MenuItem>

            <MenuItem
              onClick={(e) => {
                e.stopPropagation(); // Stop event propagation to avoid affecting the parent
                handleOpen(selectedItem); // Use the selected item for delete
                handleClose(); // Close the menu
              }}
            >
              <DeleteIcon sx={{ color: "#FFFFF" }} fontSize="medium" />
              <Typography>Delete</Typography>
            </MenuItem>
          </Menu>
        </Box>
      ))}
    </Box>
  );
};

export default HistoryForm;
