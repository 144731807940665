import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "../Icons/Warning";
import useStorageValidation from "../../hooks/useStorageValidation";
import { useSelector, useDispatch } from "react-redux";
import slices from "../../store/user/userSlice";
import { useNavigate } from "react-router-dom";
const RibbonNotification = () => {
  const { isStorageUsageAboveThreshold } = useStorageValidation();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.reducer?.user);
  const navigate = useNavigate();
  const handleClose = () => {
    const updatedUserData = {
      ...user,
      storageNotification: false,
    };
    dispatch(slices.setUser(updatedUserData));
  };

  return (
    <>
      {isStorageUsageAboveThreshold() && user?.storageNotification && (
        <Grid item xs={12} sm={12}>
          <Box>
            <Box container sx={{ background: "#f3ca8c", borderRadius: "4px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sm={11}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginLeft: "10px" }}>
                  <WarningIcon color="black" />
                  <Typography sx={{ color: "black", padding: "10px", textAlign: "center", fontFamily: "Public Sans", fontSize: "15px" }}>
                    You're out of storage! Upgrade your subscription plan to keep things running smoothly.{" "}
                  </Typography>
                  <Button
                    onClick={() => {
                      navigate("/");
                      dispatch(slices.setActiveTab(2));
                    }}
                    sx={{
                      width: { xs: "100%", sm: "auto" },
                      mt: { xs: 0, sm: 1.5 },
                      mb: { xs: 0, sm: 2 },
                      // boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                      "&:hover, &:focus": {
                        // boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                        backgroundColor: "white",
                        color: "#6a1b9a",
                      },
                      fontSize: "12px",
                      backgroundColor: "#686DFC",
                      fontWeight: "600",
                      fontFamily: "poppins",
                      color: "white",
                      textTransform: "uppercase",
                    }}
                  >
                    upgrade
                  </Button>
                </Box>
              </Box>
              <Box sm={1} sx={{ marginTop: "5px" }}>
                <Box>
                  <Typography onClick={handleClose} sx={{ padding: "10px", cursor: "pointer" }}>
                    <CloseIcon />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      )}
    </>
  );
};

export default RibbonNotification;
