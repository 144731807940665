import React from "react";

const ExpendFileIcon = () => {
  return (
    <div>
      <svg width="30" height="30" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.2"
          d="M22.0992 8.49951H28.8992C29.3501 8.49951 29.7825 8.67862 30.1013 8.99743C30.4201 9.31624 30.5992 9.74864 30.5992 10.1995V16.9995C30.5992 17.4504 30.4201 17.8828 30.1013 18.2016C29.7825 18.5204 29.3501 18.6995 28.8992 18.6995H22.0992C21.6484 18.6995 21.216 18.5204 20.8971 18.2016C20.5783 17.8828 20.3992 17.4504 20.3992 16.9995V10.1995C20.3992 9.74864 20.5783 9.31624 20.8971 8.99743C21.216 8.67862 21.6484 8.49951 22.0992 8.49951ZM11.8992 18.6995H18.6992C19.1501 18.6995 19.5825 18.8786 19.9013 19.1974C20.2201 19.5162 20.3992 19.9486 20.3992 20.3995V27.1995C20.3992 27.6504 20.2201 28.0828 19.9013 28.4016C19.5825 28.7204 19.1501 28.8995 18.6992 28.8995H11.8992C11.4484 28.8995 11.0159 28.7204 10.6971 28.4016C10.3783 28.0828 10.1992 27.6504 10.1992 27.1995V20.3995C10.1992 19.9486 10.3783 19.5162 10.6971 19.1974C11.0159 18.8786 11.4484 18.6995 11.8992 18.6995Z"
          fill="#C8C9DB"
        />
        <path
          d="M19.3013 15.9013C19.1417 16.0609 18.9252 16.1506 18.6995 16.1506C18.4738 16.1506 18.2573 16.0609 18.0977 15.9013C17.9381 15.7417 17.8484 15.5252 17.8484 15.2995C17.8484 15.0738 17.9381 14.8573 18.0977 14.6977L24.8977 7.89771C24.9767 7.81868 25.0706 7.75599 25.1738 7.71322C25.2771 7.67045 25.3877 7.64844 25.4995 7.64844C25.6113 7.64844 25.7219 7.67045 25.8252 7.71322C25.9285 7.75599 26.0223 7.81868 26.1013 7.89771C26.1803 7.97674 26.243 8.07056 26.2858 8.17382C26.3286 8.27708 26.3506 8.38775 26.3506 8.49951C26.3506 8.61128 26.3286 8.72195 26.2858 8.8252C26.243 8.92846 26.1803 9.02228 26.1013 9.10131L19.3013 15.9013ZM9.10131 26.1013C9.02228 26.1803 8.92846 26.243 8.8252 26.2858C8.72195 26.3286 8.61128 26.3506 8.49951 26.3506C8.38775 26.3506 8.27708 26.3286 8.17382 26.2858C8.07056 26.243 7.97674 26.1803 7.89771 26.1013C7.81868 26.0223 7.75599 25.9285 7.71322 25.8252C7.67045 25.7219 7.64844 25.6113 7.64844 25.4995C7.64844 25.3877 7.67045 25.2771 7.71322 25.1738C7.75599 25.0706 7.81868 24.9767 7.89771 24.8977L14.6977 18.0977C14.8573 17.9381 15.0738 17.8484 15.2995 17.8484C15.5252 17.8484 15.7417 17.9381 15.9013 18.0977C16.0609 18.2573 16.1506 18.4738 16.1506 18.6995C16.1506 18.9252 16.0609 19.1417 15.9013 19.3013L9.10131 26.1013Z"
          fill="#C8C9DB"
        />
        <path
          d="M8.49844 26.3494C8.273 26.3494 8.0568 26.2599 7.8974 26.1005C7.73799 25.941 7.64844 25.7248 7.64844 25.4994C7.64844 25.274 7.73799 25.0578 7.8974 24.8984C8.0568 24.739 8.273 24.6494 8.49844 24.6494H15.2984C15.5239 24.6494 15.7401 24.739 15.8995 24.8984C16.0589 25.0578 16.1484 25.274 16.1484 25.4994C16.1484 25.7248 16.0589 25.941 15.8995 26.1005C15.7401 26.2599 15.5239 26.3494 15.2984 26.3494H8.49844Z"
          fill="#C8C9DB"
        />
        <path
          d="M9.34844 25.4994C9.34844 25.7248 9.25888 25.941 9.09948 26.1005C8.94007 26.2599 8.72387 26.3494 8.49844 26.3494C8.273 26.3494 8.0568 26.2599 7.8974 26.1005C7.73799 25.941 7.64844 25.7248 7.64844 25.4994V18.6994C7.64844 18.474 7.73799 18.2578 7.8974 18.0984C8.0568 17.939 8.273 17.8494 8.49844 17.8494C8.72387 17.8494 8.94007 17.939 9.09948 18.0984C9.25888 18.2578 9.34844 18.474 9.34844 18.6994V25.4994ZM26.3484 15.2994C26.3484 15.5248 26.2589 15.741 26.0995 15.9005C25.9401 16.0599 25.7239 16.1494 25.4984 16.1494C25.273 16.1494 25.0568 16.0599 24.8974 15.9005C24.738 15.741 24.6484 15.5248 24.6484 15.2994V8.49941C24.6484 8.27398 24.738 8.05778 24.8974 7.89837C25.0568 7.73897 25.273 7.64941 25.4984 7.64941C25.7239 7.64941 25.9401 7.73897 26.0995 7.89837C26.2589 8.05778 26.3484 8.27398 26.3484 8.49941V15.2994Z"
          fill="#C8C9DB"
        />
        <path
          d="M18.6977 9.34941C18.4722 9.34941 18.256 9.25986 18.0966 9.10045C17.9372 8.94105 17.8477 8.72485 17.8477 8.49941C17.8477 8.27398 17.9372 8.05778 18.0966 7.89837C18.256 7.73897 18.4722 7.64941 18.6977 7.64941H25.4977C25.7231 7.64941 25.9393 7.73897 26.0987 7.89837C26.2581 8.05778 26.3477 8.27398 26.3477 8.49941C26.3477 8.72485 26.2581 8.94105 26.0987 9.10045C25.9393 9.25986 25.7231 9.34941 25.4977 9.34941H18.6977Z"
          fill="#C8C9DB"
        />
      </svg>
    </div>
  );
};

export default ExpendFileIcon;
