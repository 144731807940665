import { Box, Button, Grid, Modal, Typography, Backdrop } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#4A4C64",
  borderRadius: "8px",
  boxShadow: 24,
  p: 4,
};

const StatusUpdate = ({ open, onClose, onconfirm }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          backgroundColor: "transparent", // Set the background color of the overlay to a semi-transparent red
        },
      }}
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" color={"white"} variant="h6" component="h2">
          Status confirmation
        </Typography>
        <Typography id="modal-modal-description" color={"#A6A7C1"} sx={{ mt: 2 }}>
          Are you sure you want to change the status?{" "}
        </Typography>
        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={onClose}
              variant="contained"
              sx={{
                bottom: "0px",
                border: "1px solid #686DFC",
                color: "white",
                mt: 1.5,
                mb: 2,

                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={onconfirm}
              variant="contained"
              sx={{
                // width: "%",
                mt: 1.5,
                mb: 2,
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#DC3545",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default StatusUpdate;
