import MainLayout from "../../layouts/MainLayout";
import { Box, Button, CssBaseline, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TableComponent from "../../Components/Table";
import { useNavigate } from "react-router-dom";
import PopupModel from "../../Components/Modal";
import { useEffect, useState } from "react";
import DepositionModel from "../../Components/Modal/DepositionModel";
import { useAxios } from "../../services/http.service";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import axios from "axios";
import { Description } from "@mui/icons-material";
import StatusUpdate from "../../Components/Modal/StatusModel";
import TableDropdown from "../../Components/Dropdown/TableDropdown";
import { BiSolidEditAlt } from "react-icons/bi";
const RolePage = () => {
  const navigate = useNavigate();
  const [openDeposition, setOpenDeposition] = useState(false);
  const handleOpen = () => setOpenDeposition(true);
  const handleClose = () => setOpenDeposition(false);
  const user = useSelector((state) => state?.reducer?.user);
  const RoleHeadingTable = ["Name", "Description", "Permission", "Actions"];
  const RoleValuesKeysTable = ["name", "description", "permission"];
  const PermissionHeadingTable = ["Name", "Description", "Actions"];
  const PermissionValuesKeysTable = ["name", "description"];
  const [filterSearch, setFilterSearch] = useState({});
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const [RoleData, setRoleData] = useState([]);
  const { get, setBearerToken } = useAxios();

  const fetchData = async () => {
    const endpoint = `/permissions`;
    setBearerToken(token);
    const response = await get(endpoint);
    return response?.data;
  };

  const { data, isLoading } = useQuery(["permissionsData"], fetchData, {
    //  currentPage, searchText, filterSearch, limit
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  const finalData = data?.data;

  // defiend role api
  const getAllRoles = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "roles", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setRoleData(response);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllRoles();
  }, []);

  let finalRoleData = RoleData?.data;
   if (finalRoleData?.data) {
    const manipulatedData = RoleData?.data?.data?.data?.map((caseDoc) => ({
      // permission: caseDoc?.permission?.map((list)=>({name:list?.name,permissionId:list?._id,description:list?.description})),
      permission: caseDoc?.permissions,

      roleId: caseDoc?._id,
      name: caseDoc?.name,
      description: caseDoc?.description,
    }));
    finalRoleData = {
      ...finalRoleData,
      data: manipulatedData,
    };
   }

  return (
    <MainLayout>
      <PopupModel
        open={openDeposition}
        styleProp={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "auto" },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          height: "auto",
          backgroundColor: "#262535",
          boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
          border: "1px solid #686DFC",
        }}
        handleClose={handleClose}
        cards={<DepositionModel closeModal={handleClose} filter={filterSearch} setFilter={setFilterSearch} />}
      />
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#2B2C40",
            color: "white",
          }}
        >
          <Box
            sx={{
              minHeight: "100vh",
              maxHeight: { xs: 2000, sm: 2000 },
              position: "relative",
            }}
          >
            <CssBaseline />
            {/* here is heading  */}
            <Grid container spacing={2}>
              <Grid
                item
                xs={8}
                sm={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#F2F3FB",
                    fontFamily: "Public Sans ,sans-serif",
                  }}
                >
                  Role
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => navigate("/role/addRole")}
                  variant="contained"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    mt: { xs: 0, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                    "&:hover, &:focus": {
                      boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                      backgroundColor: "white",
                      color: "#6a1b9a",
                    },
                    fontSize: "12px",
                    backgroundColor: "#686DFC",
                    fontWeight: "600",
                    fontFamily: "poppins",
                    textTransform: "uppercase",
                  }}
                >
                  <AddIcon fontSize="small" sx={{ marginRight: "4px" }} />
                  add new Role{" "}
                </Button>
              </Grid>

              {/* table section */}
              <Grid item xs={12} sm={12}>
                {/* {finalRoleData && (
                  <TableComponent
                    filterSearch={filterSearch}
                    setFilterSearch={setFilterSearch}
                    //   filter={true}
                    handleOpen={handleOpen}
                    //   currentPage={currentPage}
                    //   setCurrentPage={setCurrentPage}
                    //   searchText={searchText}
                    //   setSearchText={setSearchText}
                    //   limit={limit}
                    //   setLimit={setLimit}
                    data={finalRoleData}
                    headingTable={RoleHeadingTable}
                    valuesKeys={RoleValuesKeysTable}
                    //   viewPageUrl={"/depositions/viewDepositions"}
                    //   deleteUrl={"/deleteDeposition"}
                    editPageUrl={"/role/UpdateRole"}
                    //   isDelete={true}
                  />
                )} */}

                <TableContainer
                  component={Paper}
                  elevation={0}
                  sx={{
                    borderRadius: 0,
                    marginTop: "30px",
                    background: "none",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      "& .MuiTableCell-root": {
                        backgroundColor: "#2B2C40",
                        color: "#C8C9DB",
                        fontFamily: "Poppins, sans-serif",
                        borderBottom: "1px solid #4A4C64",
                      },
                      "& .MuiTableHead-root": {
                        borderBottom: "1px solid #4A4C64",
                        borderTop: "1px solid #4A4C64",
                      },
                    }}
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        whiteSpace: "nowrap",
                        wordBreak: "break-word",
                        minWidth: 500,
                        "& .MuiTableCell-root": {
                          padding: "16px 10px",
                        },
                      }}
                    >
                      <TableRow sx={{ border: "none !important" }}>
                        {RoleHeadingTable.map((column) => (
                          <TableCell key={column} sx={{ color: "#C8C9DB" }}>
                            {" "}
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {finalRoleData?.data?.map((row, i) => (
           
                        <TableRow key={i}>
             
                          <TableCell
                            key={i}
                            sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordWrap: "break-word", wordBreak: "break-word", maxWidth: 250, padding: "16px 10px" }}
                            align="left"
                          >
                            {row?.name}
                          </TableCell>
                          <TableCell
                            key={i}
                            sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordWrap: "break-word", wordBreak: "break-word", maxWidth: 250, padding: "16px 10px" }}
                            align="left"
                          >
                            {row?.description}
                          </TableCell>
                          <TableCell
                            key={i}
                            sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", wordWrap: "break-word", wordBreak: "break-word", maxWidth: 250, padding: "16px 10px" }}
                            align="left"
                          >
                            
                            {row?.permission?.map((item) => {
                             return item?.name;
                            })}
                            
                          </TableCell>
                          <TableCell align="left">
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                textAlign: "center",
                              }}
                            >
                              <span>{}</span>
                              {/* <TableDropdown data={row} viewPageUrl={viewPageUrl} resetPassword={resetPassword} resetPasswordUrl={resetPasswordUrl} deleteUrl={deleteUrl} isDelete={isDelete} /> */}
                              <IconButton onClick={() => navigate('/role/UpdateRole', { state: { userData: row } })} sx={{ color: "lightgray" }}>
                                <BiSolidEditAlt size={20} />
                              </IconButton>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              <Grid
                item
                xs={8}
                sm={8}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{
                    color: "#F2F3FB",
                    fontFamily: "Public Sans ,sans-serif",
                    pt: "25px",
                  }}
                >
                  Permission
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  onClick={() => navigate("/role/addPermission")}
                  variant="contained"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    mt: { xs: 0, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                    "&:hover, &:focus": {
                      boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                      backgroundColor: "white",
                      color: "#6a1b9a",
                    },
                    fontSize: "12px",
                    backgroundColor: "#686DFC",
                    fontWeight: "600",
                    fontFamily: "poppins",
                    textTransform: "uppercase",
                  }}
                >
                  <AddIcon fontSize="small" sx={{ marginRight: "4px" }} />
                  add new Permission{" "}
                </Button>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TableComponent
                  filterSearch={filterSearch}
                  setFilterSearch={setFilterSearch}
                  handleOpen={handleOpen}
                  //   currentPage={currentPage}
                  //   setCurrentPage={setCurrentPage}
                  //   searchText={searchText}
                  //   setSearchText={setSearchText}
                  //   limit={limit}
                  //   setLimit={setLimit}
                  data={finalData}
                  headingTable={PermissionHeadingTable}
                  valuesKeys={PermissionValuesKeysTable}
                  //   viewPageUrl={"/depositions/viewDepositions"}
                  //   deleteUrl={"/deleteDeposition"}
                  editPageUrl={"/role/UpdatePermission"}
                  //   isDelete={true}
                />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </MainLayout>
  );
};

export default RolePage;
