import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import { Autocomplete, Box, Checkbox, Chip, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Paper, Select, SvgIcon, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import QueriesForm from "../../Components/Forms/QueriesForm";
import { allCategory, allItem } from "../../constant/GeneratePleading";
import { initialValuesQueries, QueriesSchemeValidation } from "../../constant/ConstantValidation";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";
import { useAxios } from "../../services/http.service";
import { styles } from "./Style";
import { Search, SearchIconWrapper, StyledInputBase } from "./Style";
import HistoryForm from "../../Components/Forms/QueriesForm/HistoryForm";
import AddIcon from "@mui/icons-material/Add";
import { useMultiFileUpload } from "../../context/useMultiFileUploade";
const QueriesPage = () => {
  const userData = useSelector((state) => state?.reducer?.user?.userData);
  const { user } = useSelector((state) => state?.reducer);
  const [chatHistory, setChatHistory] = useState(null);
  const { matter } = useMultiFileUpload();

  const { post } = useAxios();

  const formik = useFormik({
    initialValues: {
      category: chatHistory?.moduleName || "",
      items: chatHistory?.files || [],
      chatId: "",
      sources: chatHistory?.source_number || "",
      personsId: chatHistory?.person_id || "",
    },
    onSubmit: async (values, { resetForm }) => {},
    enableReinitialize: true,
  });
  const [userFile, setUserFile] = useState([]);
  const getAllFileName = async () => {
    const token = user?.userData?.token;

    // Check if formik?.values?.category exists, exit the function if not present
    if (!formik?.values?.category) {
      console.error("Category is required to fetch the file names.");
      return;
    }

    // Initialize endpoint with the category
    let endpoint = process.env.REACT_APP_BACKEND_URL + `getFileUrl/${formik.values.category}`;

    try {
      // Prepare query parameters for matter and person
      const queryParams = [];

      if (matter) {
        queryParams.push(`global=${encodeURIComponent(JSON.stringify(matter))}`);
      }

      if (formik.values.personsId) {
        queryParams.push(`person=${encodeURIComponent(formik.values.personsId)}`);
      }

      // Append query parameters if any exist
      if (queryParams.length > 0) {
        endpoint += `?${queryParams.join("&")}`;
      }

      // Call the API
      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        setUserFile(response.data);
      } else {
        console.error("Failed to fetch data. Status:", response.status);
      }
    } catch (error) {
      console.error("An error occurred while fetching file names:", error.message);
    }
  };

  const [allPersons, setAllPerson] = useState([]);
  const getAllPerson = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "person", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status == 200) {
        setAllPerson(response?.data?.data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getAllFileName();
    getAllPerson();
  }, [formik?.values?.category, formik.values.personsId, matter]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handleCategoryChange = (event) => {
    const value = event.target.value;
    // setUserFile(value);
    formik.setFieldValue("category", value);
  };
  const defaultSelectedValue = ["value4"];
  const handleClear = () => {
    setChatHistory(null);
  };

  return (
    <MainLayout>
      <Grid container pt={4} spacing={2}>
        <Grid item xs={12}>
          <Box component="form" noValidate onSubmit={formik.handleSubmit}>
            {/* Category Dropdown */}
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={3}>
                <FormControl fullWidth variant="outlined" size="small" error={formik.touched.category && Boolean(formik.errors.category)} sx={styles?.input}>
                  <InputLabel
                    id="category-label"
                    sx={{
                      color: "#C8C9DB",
                      "&.Mui-focused": { color: "#C8C9DB" },
                    }}
                  >
                    Category
                  </InputLabel>
                  <Select
                    labelId="category-label"
                    id="category"
                    name="category"
                    label="Category"
                    value={formik.values.category}
                    onBlur={formik.handleBlur}
                    onChange={handleCategoryChange}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "#323249",
                          color: "#C8C9DB",
                          maxHeight: 200,
                          overflowY: "auto",
                          "& .MuiMenuItem-root": {
                            "&:hover": {
                              backgroundColor: "#686DFC26",
                              color: "#686DFC",
                            },
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select category</em>
                    </MenuItem>
                    {allCategory?.map((value, index) => (
                      <MenuItem value={value.values} key={index}>
                        {value.title}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.category && formik.errors.category && <FormHelperText>{formik.errors.category}</FormHelperText>}
                </FormControl>
              </Grid>
              <Grid item sm={3}>
                <FormControl
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={formik.touched.fillingParty && Boolean(formik.errors.fillingParty)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      color: "#A6A7C1", // Set text color
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4A4C64", // Normal border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#C8C9DB", // Border color on hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#A6A7C1", // Yellow border color on focus
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#C8C9DB",
                    },
                  }}
                >
                  <InputLabel
                    id="fillingParty-label"
                    sx={{
                      color: "#C8C9DB",
                      // Change label color
                      "&.Mui-focused": {
                        color: "#A6A7C1", // Change label color when focused
                      },
                    }}
                  >
                    Persons
                  </InputLabel>
                  <Select
                    labelId="fillingParty-label"
                    id="fillingParty"
                    autoComplete="off"
                    name="fillingParty"
                    label="Persons"
                    value={formik.values.personsId}
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.setFieldValue("fillingParty", e.target.value);
                      formik.setFieldValue("personsId", e.target.value);
                    }}
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          backgroundColor: "#323249",
                          color: "#C8C9DB",
                          maxHeight: 200, // Adjust as needed
                          overflowY: "auto",
                          "& .MuiMenuItem-root": {
                            "&:hover": {
                              backgroundColor: "#686DFC26", // Hover background color
                              color: "#686DFC", // Hover text color
                            },
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Select person</em>
                    </MenuItem>
                    {allPersons?.map((row, index) => {
                      return (
                        <MenuItem key={index} value={row._id}>
                          {`${row?.lastName}  ${row?.firstName} `}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {formik.touched.fillingParty && formik.errors.fillingParty && <FormHelperText>{formik.errors.fillingParty}</FormHelperText>}
                </FormControl>
              </Grid>

              {/* Item Dropdown */}

              <Grid item sm={3}>
                <FormControl fullWidth variant="outlined" size="small" error={formik.touched.items && Boolean(formik.errors.items)} sx={styles?.input}>
                  <Autocomplete
                    size="small"
                    multiple
                    disableClearable
                    id="items-autocomplete"
                    options={userFile} // The array of files with fileName and fileUrl
                    getOptionLabel={(option) => option?.fileName || ""} // Display fileName in dropdown
                    value={formik.values.items ? userFile.filter((file) => formik.values.items.includes(file.fileUrl)) : []}
                    onChange={(event, newValue) => {
                      const fileUrls = newValue.map((item) => item?.fileUrl);
                      formik.setFieldValue("items", fileUrls);
                    }}
                    disableCloseOnSelect // Keep dropdown open while selecting multiple options
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Item"
                        error={formik.touched.items && Boolean(formik.errors.items)}
                        onBlur={formik.handleBlur}
                        InputLabelProps={{
                          style: {
                            color: "#C8C9DB",
                          },
                        }}
                        sx={{
                          color: "#C8C9DB",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            width: "100%",
                            color: "#A6A7C1",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#4A4C64",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#C8C9DB",
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#C8C9DB",
                          },
                          "&.Mui-focused": {
                            color: "#C8C9DB",
                          },
                        }}
                      />
                    )}
                    PaperComponent={({ children }) => (
                      <Paper
                        style={{
                          backgroundColor: "#323249",
                          color: "#C8C9DB",
                          maxHeight: 300, // Adjust as needed
                          overflowY: "auto",
                        }}
                      >
                        {children}
                      </Paper>
                    )}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          {...getTagProps({ index })}
                          label={option?.fileName} // Display fileName in tags
                          deleteIcon={
                            <SvgIcon sx={{ color: "#686DFC", width: "15px", height: "15px" }}>
                              <ClearIcon sx={{ width: "15px", height: "15px", color: "#686DFC" }} />
                            </SvgIcon>
                          }
                          sx={{
                            backgroundColor: "#686DFC26",
                            color: "#686DFC",
                            textTransform: "capitalize",
                            borderRadius: "2px",
                            width: "70px",
                            whiteSpace: "nowrap", // Prevent the text from wrapping
                            overflow: "hidden", // Hide any overflow content
                            textOverflow: "ellipsis", // Add ellipsis for long text
                            fontSize: "0.875rem",
                          }}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id} style={{ color: "#C8C9DB", display: "flex", alignItems: "center" }}>
                        <Checkbox
                          checked={selected}
                          sx={{
                            color: "#686DFC",
                            width:'30px',
                            height:'30px',
                            "&.Mui-checked": {
                              color: "#686DFC",
                            },
                          }}
                        />
                        {option.fileName}
                      </li>
                    )}
                    sx={{
                      color: "#C8C9DB",
                      "& .MuiOutlinedInput-root": {
                        color: "#C8C9DB",
                        "&.Mui-focused fieldset": {
                          borderColor: "#C8C9DB",
                        },
                      },
                      "& .MuiAutocomplete-popupIndicator": {
                        color: "#C8C9DB",
                      },
                      "& .MuiAutocomplete-clearIndicator": {
                        color: "#C8C9DB",
                      },
                      "& .MuiListSubheader-root, .MuiAutocomplete-option": {
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                      },
                    }}
                  />
                  {formik.touched.items && formik.errors.items && <FormHelperText>{formik.errors.items}</FormHelperText>}
                </FormControl>
              </Grid>

              {/* Autocomplete */}
              <Grid item sm={3}>
                <FormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "5px",
                      color: "#A6A7C1", // Set text color
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4A4C64",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#C8C9DB",
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#C8C9DB",
                    },
                  }}
                >
                  <Autocomplete
                    sx={{ color: "#C8C9DB" }}
                    id="sources"
                    options={[1, 2, 3, 4, 5, 6, 7, 8]} // Displaying numbers from 1 to 8
                    getOptionLabel={(option) => `${option}`} // Convert the numbers to strings
                    value={formik.values.sources || null} // Set a single value, not an array
                    onChange={(event, value) => {
                      formik.setFieldValue("sources", value);
                    }}
                    isOptionEqualToValue={(option, value) => option === value} // Direct comparison for numbers
                    defaultValue={defaultSelectedValue} // Ensure this is a single value, not an array
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        label="Sources"
                        error={formik.touched.sources && Boolean(formik.errors.sources)}
                        sx={{
                          color: "#C8C9DB",
                          "& .MuiOutlinedInput-root": {
                            borderRadius: "5px",
                            width: "100%",
                            color: "#A6A7C1", // Set text color
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#4A4C64",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#C8C9DB",
                            },
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#C8C9DB",
                          },
                          "&.Mui-focused": {
                            color: "#C8C9DB", // Change label color when focused
                          },
                        }}
                        InputLabelProps={{
                          style: {
                            color: "#C8C9DB",
                          },
                        }}
                      />
                    )}
                    PaperComponent={({ children }) => (
                      <Paper
                        style={{
                          backgroundColor: "#323249",
                          color: "#C8C9DB",
                          maxHeight: 300, // Adjust as needed
                          overflowY: "auto",
                        }}
                      >
                        {children}
                      </Paper>
                    )}
                    renderOption={(props, option) => (
                      <li {...props} key={option} style={{ color: "#C8C9DB" }}>
                        {option}
                      </li>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container pt={2}>
        <Grid item xs={3} sx={{ borderRight: "1px solid #4A4C64", backgroundColor: "#2B2C40", color: "white", height: "84vh" }}>
          <Paper
            sx={{
              p: "15px",
              display: "flex",
              flexDirection: "column",
              backgroundColor: "#2B2C40",
              color: "white",
              borderBottom: "1px solid #4A4C64",
              borderRadius: 0,
            }}
          >
            <Box onClick={handleClear} sx={{ display: "flex", cursor: "pointer", alignItems: "center", gap: "20px", flexGrow: 1 }}>
              <Box
                sx={{
                  width: "30px",

                  height: "30px",
                  border: "2px solid #686DFC", // Set the border
                  borderRadius: "50%", // Make it a circle
                  display: "flex", // Flexbox for centering content
                  justifyContent: "center", // Center horizontally
                  alignItems: "center", // Center vertically
                  color: "#686DFC", // Icon color
                }}
              >
                <AddIcon />
              </Box>
              <Typography sx={{ color: "#686DFC", fontSize: "20px" }}>New Chat</Typography>

              {/* <Search>
                <SearchIconWrapper>
                  <SearchIcon sx={{ width: "20px", color: "#FFFFFF" }} />
                </SearchIconWrapper>
                <StyledInputBase placeholder="Search chat..." inputProps={{ "aria-label": "Search chat..." }} />
              </Search> */}
            </Box>
          </Paper>
          <HistoryForm chatHistory={chatHistory} setChatHistory={setChatHistory} />
        </Grid>
        <Grid item xs={9}>
          <Paper sx={{ display: "flex", flexDirection: "column", backgroundColor: "#242333" }}>
            {/* <Box sx={{ width: "100%" }}> */}

            <QueriesForm chatValue={formik.values} chatHistory={chatHistory} setChatHistory={setChatHistory} />
            {/* </Box> */}
          </Paper>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default QueriesPage;
