import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const toastConfig = {
 position: "bottom-right",

};

const info = (message) => {
  toast.info(message, toastConfig);
};

const success = (message) => {
  toast.success(message, toastConfig);
};

const error = (message) => {
  toast.error(message, toastConfig);
};

const warning = (message) => {
  toast.warning(message, toastConfig);
};

export default { info, success, error, warning };