import { useCallback, useState } from "react";
import PopupModel from "../Components/Modal";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const useFileActions = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const handleFileAction = useCallback((filePath, action) => {
    if (!filePath) return;

    let url = filePath;
    if (action === "view") {
      if (filePath.endsWith(".xlsx")) {
    
       
        const url = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(filePath)}`;
        setIsModalOpen(true);
        setModalContent(url);
      } else if (filePath.endsWith(".docx")) {
        // Use Microsoft Office Viewer for .docx files
        const url = `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(filePath)}`;
        setIsModalOpen(true);
        setModalContent(url);
      } else {
        // Handle other file types (images, PDFs, etc.)
        setIsModalOpen(true);
        setModalContent(filePath);
      }
    } else if (action === "generated") {
      window.open(url, "_blank");
    } else if (action === "download") {
      const isProduction = process.env.REACT_APP_BACKEND_URL === "https://soon.magicpleadings.ai/api/";

      if (isProduction && !url.startsWith("https://")) {
        url = url.replace("http://", "https://");
      }

      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/octet-stream",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const link = document.createElement("a");
          const originalFileName = filePath.split("/").pop();
          const cleanedFileName = originalFileName.replace(/^\d+-\d+-/, "");

          link.href = window.URL.createObjectURL(blob);
          link.setAttribute("download", cleanedFileName);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.error("Error during file download:", error);
        });
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
  };

  const FileViewModal = () => {
    return (
      <>
        <PopupModel
          open={isModalOpen}
          styleProp={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: { xs: "90%", sm: "80%" },
            maxWidth: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 5,
            backgroundColor: "#262535",
            boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
            border: "1px solid #686DFC",
          }}
          handleClose={handleCloseModal}
          cards={
            <Box>
              <Box style={{ position: "relative" }}>
                <Typography
                  variant="h5"
                  sx={{ color: "#E6E7F2", fontFamily: "Public Sans ,sans-serif" }}
                >
                  View File
                </Typography>
                <Divider sx={{ backgroundColor: "#4A4C64", marginY: "10px" }} />
                <IconButton
                  onClick={handleCloseModal}
                  sx={{
                    position: "absolute",
                    top: "-10px",
                    right: "-12px",
                    color: "#FFFFFF",
                    zIndex: 1000,
                  }}
                >
                  <ClearIcon />
                </IconButton>

               
                  <iframe
                    src={modalContent}
                    title="File Preview"
                    style={{ width: "100%", height: "70vh", border: "none" }}
                  />
         
              </Box>
            </Box>
          }
        />
      </>
    );
  };


  const handleDownloadZip = useCallback((files) => {
    const zip = new JSZip();
    const filesData = [...files.file1, ...files.file2]; // Corrected line
    
    const filePromises = filesData.map(async (fileUrl, index) => {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
 
      const fileName = `file${index + 1}${fileUrl.slice(fileUrl.lastIndexOf('.'))}`;
      zip.file(fileName, blob);
    });
  
    Promise.all(filePromises).then(() => {
      zip.generateAsync({ type: 'blob' }).then((zipContent) => {
        saveAs(zipContent, 'files.zip'); 
      });
    });
  }, []);
  return { handleFileAction, FileViewModal,handleDownloadZip };
};

export default useFileActions;
