import { useCallback } from 'react';

const useScrollToRef = (ref) => {
  const scrollToRef = useCallback(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [ref]);

  return scrollToRef;
};

export default useScrollToRef;
