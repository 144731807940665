import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import React from "react";

const Pagination = ({ setCurrentPage, currentPage, limit, setLimit, totalPage }) => {
  const handleChange = (event) => {
    setLimit(event.target.value);
  };

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={8}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "10px",
          mt: 1,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            // marginTop: "5px",
            color: "#C8C9DB",
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "12px",
          }}
        >
          Showing {currentPage} to {totalPage}
          {/* of {limit} entries */}
        </Typography>
        <Box sx={{ minWidth: 80 }}>
          <FormControl
            fullWidth
            size="small"
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "5px",
                color: "#A6A7C1", // Set text color
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
              },
              "& .MuiSvgIcon-root": {
                color: "#C8C9DB",
              },
            }}
          >
            <Select value={limit} onChange={(event) => setLimit(event.target.value)} displayEmpty>
              {[10, 50, 100, 200, 500, 1000].map((option) => (
                <MenuItem key={option} value={option} selected={limit == option} sx={{ paddingTop: "0", paddingBottom: "0"}}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Typography
          variant="h5"
          sx={{
            // marginTop: "5px",
            color: "#C8C9DB",
            fontFamily: "Poppins",
            fontWeight: "400",
            fontSize: "12px",
          }}
        >
          entries per page
        </Typography>
      </Grid>
      <Grid item xs={12} sm={4} spacing={2} sx={{ display: "flex", justifyContent: "flex-end", gap: 2, paddingRight: "16px", marginTop: "10px" }}>
        {currentPage > 1 && (
          <Button
            onClick={() => setCurrentPage((e) => (e == 1 ? 1 : e - 1))}
            variant="contained"
            sx={{
              width: "120px",
              boxShadow: "0px 4px 8px #4A4C64",
              "&:hover, &:focus": {
                boxShadow: "0px 6px 12px #4A4C64",
                backgroundColor: "darkgray",
              },
              fontSize: "12px",
              backgroundColor: "#4A4C64",
              fontWeight: "600",
              fontFamily: "poppins",
            }}
          >
            Previous
          </Button>
        )}

        {currentPage < totalPage && (
          <Button
            onClick={() => setCurrentPage((e) => e + 1)}
            variant="contained"
            sx={{
              width: "120px",
              boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
              "&:hover, &:focus": {
                boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                backgroundColor: "white",
                color: "#6a1b9a",
              },
              fontSize: "12px",
              backgroundColor: "#686DFC",
              fontWeight: "600",
              fontFamily: "poppins",
            }}
          >
            Next
          </Button>
        )}
        {/* {totalPage > currentPage  && (
          <>
            <Button
              onClick={() => setCurrentPage((e) => (e == 1 ? 1 : e - 1))}
              variant="contained"
              sx={{
                width: "120px",

                bottom: "0px",

                mt: 1.5,
                mb: 2,
                boxShadow: "0px 4px 8px #4A4C64",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px #4A4C64",
                  backgroundColor: "darkgray",
                  // color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#4A4C64",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              previous
            </Button>
            <Button
              onClick={() => setCurrentPage((e) => e + 1)}
              variant="contained"
              sx={{
                width: "120px",
                mt: 1.5,
                mb: 2,
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Next
            </Button>
          </>
        )} */}
      </Grid>
    </Grid>
  );
};

export default Pagination;
