import React from "react";

const CourtIcon = ({ colors }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.3484 12.9782C14.3484 13.1877 14.1779 13.3588 13.9664 13.3588H6.02159C5.81096 13.3588 5.63867 13.1877 5.63867 12.9782C5.63867 12.7664 5.81096 12.595 6.02159 12.595H13.9664C14.1779 12.595 14.3484 12.7664 14.3484 12.9782Z"
        fill={colors}
      />
      <path
        d="M9.9929 12.061C9.78227 12.061 9.61133 11.8243 9.61133 11.5329V0.529022C9.61133 0.236983 9.78249 -3.05176e-05 9.9929 -3.05176e-05C10.2044 -3.05176e-05 10.3745 0.236983 10.3745 0.529022V11.5329C10.3745 11.8243 10.2044 12.061 9.9929 12.061Z"
        fill={colors}
      />
      <path
        d="M12.629 12.1449C12.629 12.3282 12.5255 12.4789 12.3974 12.4789H7.58812C7.46093 12.4789 7.35742 12.3282 7.35742 12.1449C7.35742 11.9589 7.46093 11.808 7.58812 11.808H12.3974C12.5255 11.808 12.629 11.9589 12.629 12.1449Z"
        fill={colors}
      />
      <path
        d="M16.154 1.03005C16.154 1.13469 15.9109 1.2179 15.6132 1.2179H4.37146C4.0731 1.2179 3.83203 1.13469 3.83203 1.03005C3.83203 0.925413 4.0731 0.841522 4.37146 0.841522H15.613C15.9109 0.841522 16.154 0.925413 16.154 1.03005Z"
        fill={colors}
      />
      <path d="M7.62946 6.86625H0.00195312L3.81582 0.929413L7.62946 6.86625ZM0.57498 6.55324H7.05733L3.81582 1.50808L0.57498 6.55324Z" fill={colors} />
      <path d="M3.81612 10.236C5.92286 10.236 7.63089 8.6574 7.63089 6.7092H0C0 8.6574 1.70938 10.236 3.81612 10.236Z" fill={colors} />
      <path d="M19.9989 6.86625H12.3691L16.1837 0.929413L19.9989 6.86625ZM12.9419 6.55324H19.4266L16.1837 1.50808L12.9419 6.55324Z" fill={colors} />
      <path d="M16.1837 10.236C18.2902 10.236 19.9996 8.6574 19.9996 6.7092H12.3691C12.3691 8.6574 14.0754 10.236 16.1837 10.236Z" fill={colors} />
    </svg>
  );
};

export default CourtIcon;
