import React from 'react'

const CasesIcon = ({colors}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={colors}
        d="M6.906 9.327h2.948c.02-.126.063-.253.126-.358l.316-.61H6.927a.476.476 0 00-.484.484c-.021.273.19.484.463.484z"
      ></path>
      <path
        fill={colors}
        d="M17.56 17.117h-1.98V14.57h-.968v2.57h-7.2a.476.476 0 00-.485.483v.59a.75.75 0 01-.758.737.746.746 0 01-.737-.737V7.6c0-.252-.063-.505-.169-.737h5.79l.485-.968H3.705C2.758 5.895 2 6.653 2 7.6v.737c0 .274.21.485.484.485h1.98v9.39c0 .948.757 1.705 1.705 1.705h10.148c.948 0 1.706-.758 1.706-1.705v-.59a.478.478 0 00-.463-.505z"
      ></path>
      <path
        fill={colors}
        d="M10.062 10.506H6.904a.477.477 0 00-.484.484c0 .274.21.484.484.484H10.8a2.76 2.76 0 01-.737-.968zM11.768 12.632H6.904a.477.477 0 00-.484.485c0 .273.21.484.484.484h4.885a1.23 1.23 0 01-.042-.274v-.442c0-.084 0-.168.02-.253zM6.42 15.264c0 .274.21.485.484.485h6.232c.274 0 .485-.211.485-.485a.476.476 0 00-.485-.484H6.904a.476.476 0 00-.484.484zM21.96 9.39l-1.663-3.306a.38.38 0 00-.295-.21l-2.274.02a1.398 1.398 0 00-.631-.757v-.442A.701.701 0 0016.402 4a.701.701 0 00-.695.695v.442a1.398 1.398 0 00-.632.758l-2.274-.021c-.126 0-.231.084-.294.21L10.843 9.39a.553.553 0 00-.042.316c.295 1.032 1.074 1.516 2.021 1.516.948 0 1.748-.484 2.022-1.516a.42.42 0 00-.042-.316l-1.306-2.569h1.558c.106.316.337.569.632.737v3.285c0 .82-.632 1.516-1.453 1.621l-1.284.147c-.148.021-.253.127-.253.274v.442c0 .148.127.274.274.274h6.822a.279.279 0 00.273-.274v-.442c0-.147-.105-.252-.252-.274l-1.285-.147a1.623 1.623 0 01-1.452-1.621V7.579c.294-.168.505-.42.631-.737h1.558l-1.305 2.57a.552.552 0 00-.042.315c.294 1.032 1.073 1.516 2.021 1.516s1.748-.484 2.021-1.516c.063-.105.042-.232 0-.337zm-8.064-.126h-2.168l1.095-2.148 1.073 2.148zm4.99 0l1.074-2.148 1.095 2.148h-2.169z"
      ></path>
    </svg>
  )
}

export default CasesIcon
