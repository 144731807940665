import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useState } from "react";
import axios from "axios";
import { Grid, TextField, Button, Autocomplete, FormControl, Select, MenuItem, InputLabel, FormHelperText, Paper } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";
import { useAxios } from "../../../services/http.service";
import { state } from "../../../constant/ConstantStateData";
const UpdateCourtForm = () => {
  const { token } = useSelector((state) => state?.reducer?.user?.userData);

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  const dispatch = useDispatch();
  let stateNames = state;
  const [emailError, setEmailError] = useState(null);
  const navigate = useNavigate();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();

  const { userData } = location.state || {};

  const queryClient = useQueryClient();
  const courtData = userData;
  const { setBearerToken, put } = useAxios();
  const setClientData = async (newData) => {
    setBearerToken(token);
    const response = await put(`/court`, newData);
    return response.data;
  };

  const mutation = useMutation(setClientData, {
    onSuccess: () => {
      showSuccessToastMessage("Record is successfull updated!");
      navigate("/courts");
      queryClient.invalidateQueries("courtData");
    },
    onError: () => {
      showErrorToastMessage("Something was wrong!");
    },
  });
  const formik = useFormik({
    initialValues: {
      courtId: courtData?._id,
      name: courtData?.name || "",
      type: courtData?.type || "",
      address: courtData?.address || "",
      city: courtData?.city || "",
      state: courtData?.state || "",
      zipCode: courtData?.zipCode || "",
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      type: Yup.string(),
      address: Yup.string().min(10),
      city: Yup.string().matches(/^[A-Za-z\s]+$/, "City must contain only letters and spaces."),
      state: Yup.string(),
      zipCode: Yup.string().matches(/^\d{5}$/, "Zip code must be at least 5 characters."),
    }),

    onSubmit: async (values, { resetForm }) => {
      mutation.mutate(values);
    },
  });

  return (
    <Box
      sx={{
        // minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
      }}
    >
      <CssBaseline />
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={formik.touched.name && formik.errors?.name}
              autoComplete="given-name"
              name="name"
              required
              id="name"
              label="Court name"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              helperText={formik.touched?.name && formik.errors?.name}
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.name && Boolean(formik.errors.name) ? "#D32F2F" : "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.name && Boolean(formik.errors.name) ? "#D32F2F" : "#C8C9DB",
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: formik.touched?.name && Boolean(formik.errors?.name) ? "#D32F2F" : "#C8C9DB",
                },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched.type && Boolean(formik.errors.type)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C9DB", // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A6A7C1", // Yellow border color on focus
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <InputLabel
                id="type-label"
                sx={{
                  color: "#C8C9DB",
                  // Change label color
                  "&.Mui-focused": {
                    color: "#A6A7C1", // Change label color when focused
                  },
                }}
              >
                Court type
              </InputLabel>
              <Select
                labelId="type-label"
                id="type"
                autoComplete="off"
                name="type"
                label="court type"
                value={formik.values.type}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.setFieldValue("type", e.target.value);
                  // formik.setFieldValue("type", e.target.value);
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      '& .MuiMenuItem-root': {
                        '&:hover': {
                          backgroundColor: '#686DFC26', // Hover background color
                          color: '#686DFC', // Hover text color
                        },
                      },
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select court type</em>
                </MenuItem>
                {[
                  "Supreme Court of the United States",
                  "United States Courts of Appeals (Circuit Courts)",
                  "United States District Courts",
                  "United States Court of International Trade",
                  "United States Tax Court",
                  "Military Courts (Courts-Martial)",
                  "State Courts",
                  "Specialized Courts",
                  "Tribal Courts ",
                ]?.map((value, index) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {value}
                    </MenuItem>
                  );
                })}
              </Select>
              {formik.touched.type && formik.errors.type && <FormHelperText>{formik.errors.type}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              error={formik.touched.address && formik.errors?.address}
              id="address"
              label="Address"
              name="address"
              variant="outlined"
              size="small"
              autoComplete="address"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.address}
              helperText={formik.touched?.address && formik.errors?.address}
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray", // Border color on hover
                  },
                },
              }}
              InputLabelProps={{
                style: { color: "#C8C9DB" },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextField
              error={formik.touched.city && formik.errors?.city}
              id="city"
              label="City"
              name="city"
              variant="outlined"
              size="small"
              autoComplete="city"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.city}
              helperText={formik.touched?.city && formik.errors?.city}
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray", // Border color on hover
                  },
                },
              }}
              InputLabelProps={{
                style: { color: "#C8C9DB" },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Autocomplete
              id="state"
              options={stateNames}
              disableClearable={true}
              getOptionLabel={(option) => option}
              value={formik.values.state || ""}
              onChange={(event, newValue) => {
                formik.setFieldValue("state", newValue);
              }}
              onBlur={formik.handleBlur}
              PaperComponent={({ children }) => (
                <Paper
                  style={{
                    backgroundColor: "#323249",
                    "& .MuiAutocomplete-listbox .MuiAutocomplete-option.Mui-hover": {
                      bgcolor: "red",
                    },
                    color: "#C8C9DB",
                    maxHeight: 300, // Adjust as needed
                    overflowY: "auto",
                  }}
                >
                  {children}
                </Paper>
              )}
              ListboxProps={{
                sx: {
                  maxHeight: 200,
                  overflowY: "auto",
                  "& .MuiAutocomplete-option": {
                    "&:hover": {
                      backgroundColor: "#686DFC26",
                      color: "#686DFC",
                    },
                  },
                  "& .MuiAutocomplete-noOptions": {
                    color: "#FF7799",  // Customize the color of the noOptionsText
                  },
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formik.touched.state && formik.errors?.state}
                  id="state"
                  label="State"
                  name="state"
                  variant="outlined"
                  size="small"
                  autoComplete="state"
                  placeholder="Select state"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.state}
                  helperText={formik.touched?.state && formik.errors?.state}
                  sx={{
                    width: "100%",
                    borderRadius: "5px",
                    "& .MuiOutlinedInput-root": {
                      color: "#A6A7C1", // Set text color
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4A4C64", // Normal border color
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#A6A7C1", // Border color on hover
                      },
                    },
                    "& .MuiSvgIcon-root": {
                      color: "#A6A7C1",
                    },
                  }}
                  InputLabelProps={{
                    style: { color: "#C8C9DB" },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              error={formik.touched.zipCode && formik.errors?.zipCode}
              id="zipCode"
              label="Zip code"
              name="zipCode"
              variant="outlined"
              size="small"
              autoComplete="zipCode"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.zipCode}
              helperText={formik.touched?.zipCode && formik.errors?.zipCode}
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A6A7C1", // Border color on hover
                  },
                },
              }}
              InputLabelProps={{
                style: { color: "#C8C9DB" },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          '{" "}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row-reverse" },
              gap: 1,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 0, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                "&:disabled": {
                  backgroundColor: "#4A4C64",
                  color: "white",
                  boxShadow: "0px 4px 8px #4A4C64",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Submit
            </Button>

            <Button
              onClick={() => navigate("/courts")}
              variant="contained"
              sx={{
                // position: "absolute",
                bottom: "0px", // Adjust as needed
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px #4A4C64",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px #4A4C64",
                  backgroundColor: "darkgray",
                  // color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#4A4C64",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UpdateCourtForm;
