import React from "react";
import PDFIcon from "./PDFIcon";

const LoadingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="40" display="block" preserveAspectRatio="xMidYMid" viewBox="0 0 100 100" style={{}}>
      <circle cx="50" cy="50" r="35" fill="none" stroke="#FFFFFF" strokeDasharray="164.93361431346415 56.97787143782138" strokeWidth="6">
        <animateTransform attributeName="transform" dur="1s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"></animateTransform>
      </circle>
      <g transform="translate(25,25)">
        <PDFIcon/>
      </g>
    </svg>
  );
};

export default LoadingIcon;
