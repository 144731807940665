import { Box, Button, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
export const initialValues = {
  name: "",
  summaryOfLaw: "",
  caseOneCitation: "",
  factOne: "",
  lawOne: "",
  rullingOne: "",
  issueCaseFileOne: "",
  caseTwoCitation: "",
  factTwo: "",
  lawTwo: "",
  rullingTwo: "",
  issueCaseFileTwo: "",
  caseThreeCitation: "",
  factThree: "",
  lawThree: "",
  rullingThree: "",
  issueCaseFileThree: "",
};
export const validationSchemaStepOne = Yup.object({
  name: Yup.string().required("Issue name is required."),
  summaryOfLaw: Yup.string().required("Summary of law is required.").max(5000, "The summary of the law cannot exceed 5000 characters."),
});
const SUPPORTED_FORMATS = ["application/pdf"];
const FILE_SIZE = 5 * 1024 * 1024; // 5MB in bytes;
export const validationSchemaStepTwo = Yup.object({
  caseOneCitation: Yup.string().required("Case 1 citation  is required."),
  factOne: Yup.string().required("Fact is required.").max(5000, "Fact must be at least 5000 characters."),
  lawOne: Yup.string().required("Law  is required.").max(5000, "Law must be at least 5000 characters."),
  rullingOne: Yup.string().required("Ruling is required.").max(5000, "Ruling must be at least 5000 characters."),
  issueCaseFileOne: Yup.mixed()
    .nullable()
    .required(" Case file 1 is a required field.")
    .test("FILE_SIZE", "Uploaded file is too big! Allowed 5MB only.", (value) => !value || !value?.size || (value?.size && value?.size <= FILE_SIZE))
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || !value.type || (value.type && SUPPORTED_FORMATS.includes(value.type))),
});
const isAnyFieldFilled = (fields, context) => {
  return fields.some((field) => context.parent[field]);
};
export const validationSchemaStepThree = Yup.object().shape({
  caseTwoCitation: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["factTwo", "lawTwo", "rullingTwo", "issueCaseFileTwo"], this) || !!value;
    }),
  factTwo: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseTwoCitation", "lawTwo", "rullingTwo", "issueCaseFileTwo"], this) || !!value;
    }),
  lawTwo: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseTwoCitation", "factTwo", "rullingTwo", "issueCaseFileTwo"], this) || !!value;
    }),
  rullingTwo: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseTwoCitation", "factTwo", "lawTwo", "issueCaseFileTwo"], this) || !!value;
    }),
  issueCaseFileTwo: Yup.mixed()
    .nullable()
    .test("is-required", "Case file 2 is a required field.", function (value) {
      return !isAnyFieldFilled(["caseTwoCitation", "factTwo", "lawTwo", "rullingTwo"], this) || !!value;
    })
    .test("FILE_SIZE", "Uploaded file is too big! Allowed 5MB only.", (value) => !value || !value.size || (value.size && value.size <= FILE_SIZE))
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || !value.type || (value.type && SUPPORTED_FORMATS.includes(value.type))),
});
export const validationSchemaStepFour = Yup.object().shape({
  caseThreeCitation: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["factThree", "lawThree", "rullingThree", "issueCaseFileThree"], this) || !!value;
    }),
  factThree: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseThreeCitation", "lawThree", "rullingThree", "issueCaseFileThree"], this) || !!value;
    }),
  lawThree: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseThreeCitation", "factThree", "rullingThree", "issueCaseFileThree"], this) || !!value;
    }),
  rullingThree: Yup.string()
    .nullable()
    .test("is-required", "This field is required when any other fields are filled in.", function (value) {
      return !isAnyFieldFilled(["caseThreeCitation", "factThree", "lawThree", "issueCaseFileThree"], this) || !!value;
    }),
  issueCaseFileThree: Yup.mixed()
    .nullable()
    .test("is-required", "Case file 3 is a required field.", function (value) {
      return !isAnyFieldFilled(["caseThreeCitation", "factThree", "lawThree", "rullingThree"], this) || !!value;
    })
    .test("FILE_SIZE", "Uploaded file is too big! Allowed 5MB only.", (value) => !value || !value.size || (value.size && value.size <= FILE_SIZE))
    .test("FILE_FORMAT", "Uploaded file has unsupported format.", (value) => !value || !value.type || (value.type && SUPPORTED_FORMATS.includes(value.type))),
});
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
export const IssueRenderStep = (activeStep, formik) => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const handleUpload = (event) => {
    setSelectedFiles([...selectedFiles]);
  };
  const handleFileUploadOne = (event) => {
    formik.setFieldValue("issueCaseFileOne", event.currentTarget.files[0]);
  };
  const handleFileUploadTwo = (event) => {
    formik.setFieldValue("issueCaseFileTwo", event.currentTarget.files[0]);
  };
  const handleFileUploadThree = (event) => {
    formik.setFieldValue("issueCaseFileThree", event.currentTarget.files[0]);
  };

  const handleRemoveFileOne = () => {
    formik.setFieldValue("issueCaseFileOne", null);
  };
  const handleRemoveFileTwo = () => {
    formik.setFieldValue("issueCaseFileTwo", null);
  };
  const handleRemoveFileThree = () => {
    formik.setFieldValue("issueCaseFileThree", null);
  };

  const [charCount, setCharCounts] = useState({
    summaryOfLaw: 0,
    factOne: 0,
    lawOne: 0,
    rullingOne: 0,
    factTwo: 0,
    lawOne: 0,
    rullingTwo: 0,
    lawTwo: 0,
    factThree: 0,
    lawThree: 0,
    rullingThree: 0,
  });

  useEffect(() => {
    const updatedCharCounts = Object.keys(formik.values).reduce((counts, fieldName) => {
      if (charCount.hasOwnProperty(fieldName)) {
        counts[fieldName] = formik.values[fieldName]?.length || 0;
      }
      return counts;
    }, {});
    setCharCounts(updatedCharCounts);
  }, [formik.values]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setCharCounts((prevCounts) => ({
      ...prevCounts,
      [name]: value.length,
    }));
    formik.setFieldValue(name, value);
  };

  switch (activeStep) {
    case 0:
      return (
        <>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Issue name"
                name="name"
                variant="outlined"
                size="small"
                required
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
                sx={{
                  width: "100%",
                  mb: "20px",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    color: "#A6A7C1", // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: formik.touched.name && Boolean(formik.errors.name) ? "#D32F2F" : "#4A4C64", // Normal border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: formik.touched.name && Boolean(formik.errors.name) ? "#D32F2F" : "#C8C9DB",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#A6A7C1", // Yellow border color on focus
                    },
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: formik.touched?.name && Boolean(formik.errors?.name) ? "#D32F2F" : "#C8C9DB",
                  },
                }}
                InputProps={{
                  style: {
                    color: "#A6A7C1",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                multiline
                rows={6}
                label="Summary of law"
                name="summaryOfLaw"
                variant="outlined"
                required
                value={formik.values.summaryOfLaw}
                onChange={handleInputChange}
                onBlur={formik.handleBlur}
                error={formik.touched.summaryOfLaw && Boolean(formik.errors.summaryOfLaw)}
                helperText={formik.touched.summaryOfLaw && formik.errors.summaryOfLaw}
                sx={{
                  width: "100%",
                  mb: "20px",
                  borderRadius: "5px",
                  "& .MuiOutlinedInput-root": {
                    color: "#A6A7C1", // Set text color
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: formik.touched.summaryOfLaw && Boolean(formik.errors.summaryOfLaw) ? "#D32F2F" : "#4A4C64", // Normal border color
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: formik.touched.summaryOfLaw && Boolean(formik.errors.summaryOfLaw) ? "#D32F2F" : "#C8C9DB",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#A6A7C1", // Yellow border color on focus
                    },
                    "& .MuiInputBase-input": {
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      msOverflowStyle: "none", // IE and Edge
                      scrollbarWidth: "none", // Firefox
                    },
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: formik.touched?.summaryOfLaw && Boolean(formik.errors?.summaryOfLaw) ? "#D32F2F" : "#C8C9DB",
                  },
                }}
                InputProps={{
                  style: {
                    color: "#A6A7C1",
                  },
                }}
              />

              <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right" }}>
                {charCount?.summaryOfLaw} / 5000
              </Typography>
            </Grid>
          </Grid>
        </>
      );
    case 1:
      return (
        <>
          <TextField
            fullWidth
            label="Case 1 Citation"
            name="caseOneCitation"
            variant="outlined"
            required
            value={formik.values?.caseOneCitation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.caseOneCitation && Boolean(formik.errors?.caseOneCitation)}
            helperText={formik.touched?.caseOneCitation && formik.errors?.caseOneCitation}
            sx={{
              width: "100%",
              mb: "20px",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                color: "#A6A7C1", // Set text color
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: formik.touched?.caseOneCitation && Boolean(formik.errors?.caseOneCitation) ? "#D32F2F" : "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: formik.touched?.caseOneCitation && Boolean(formik.errors?.caseOneCitation) ? "#D32F2F" : "#C8C9DB",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#A6A7C1", // Yellow border color on focus
                },
              },
            }}
            InputLabelProps={{
              style: { color: formik.touched?.caseOneCitation && Boolean(formik.errors?.caseOneCitation) ? "#D32F2F" : "#C8C9DB" },
            }}
            InputProps={{
              style: {
                color: "#A6A7C1",
              },
            }}
          />
          <TextField
            fullWidth
            label="Fact"
            required
            multiline
            rows={6}
            name="factOne"
            variant="outlined"
            value={formik.values?.factOne}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.factOne && Boolean(formik.errors?.factOne)}
            helperText={formik.touched?.factOne && formik.errors?.factOne}
            sx={{
              width: "100%",
              borderRadius: "5px",
              mb: "20px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#A6A7C1", // Yellow border color on focus
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                // height: "200px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.factOne} / 5000
          </Typography>
          <TextField
            fullWidth
            label="Law"
            required
            name="lawOne"
            multiline
            rows={4}
            variant="outlined"
            value={formik.values?.lawOne}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.lawOne && Boolean(formik.errors?.lawOne)}
            helperText={formik.touched?.lawOne && formik.errors?.lawOne}
            sx={{
              width: "100%",
              mb: "20px",

              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                // height: "120px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.lawOne} / 5000
          </Typography>
          <TextField
            fullWidth
            label="Ruling"
            required
            name="rullingOne"
            multiline
            rows={6}
            variant="outlined"
            value={formik.values?.rullingOne}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.rullingOne && Boolean(formik.errors?.rullingOne)}
            helperText={formik.touched?.rullingOne && formik.errors?.rullingOne}
            sx={{
              width: "100%",
              mb: "20px",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                // height: "200px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.rullingOne} / 5000
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box
                variant="p"
                onClick={handleUpload}
                sx={{
                  mb: "10px",
                  color: "#C8C9DB",
                  cursor: "pointer !important",
                }}
              >
                Upload Case 1 *
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  borderRadius: "4px",
                  borderTop: formik?.values?.issueCaseFileOne ? "1px solid #4A4C64" : "none",
                  borderBottom: formik?.values?.issueCaseFileOne ? "1px solid #4A4C64" : "none",
                  borderRight: formik?.values?.issueCaseFileOne ? "1px solid #4A4C64" : "none",
                }}
              >
                {" "}
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid #4A4C64",
                    borderRadius: formik?.values?.issueCaseFileOne ? "" : "3px",
                    width: "93%",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    sx={{ color: "#686DFC", borderRadius: "2px", border: "1px solid #686DFC", backgroundColor: "transparent", "&:hover , &:focus": { backgroundColor: "transparent" } }}
                  >
                    Choose file
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf , .doc"
                      onChange={(e) => handleFileUploadOne(e)}
                      error={formik.touched.issueCaseFileOne && Boolean(formik?.errors?.issueCaseFileOne)}
                      helperText={formik?.touched?.issueCaseFileOne && formik?.errors?.issueCaseFileOne}
                    />
                  </Button>
                  <Typography sx={{ color: "#C8C9DB", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 140px)" }}>
                    {formik?.values?.issueCaseFileOne?.name ? formik?.values?.issueCaseFileOne?.name : formik?.values?.issueCaseFileOne ? formik?.values?.issueCaseFileOne : "No file selected"}
                  </Typography>
                </Box>
                <Box>
                  {formik?.values?.issueCaseFileOne && (
                    <Button
                      onClick={handleRemoveFileOne}
                      sx={{
                        color: "#C8C9DB",
                        backgroundColor: "transparent",
                        minWidth: "35px",
                        "&:hover, &:focus": { background: "transparent" },
                      }}
                    >
                      x
                    </Button>
                  )}
                </Box>
              </Box>
              {formik?.touched?.issueCaseFileOne && formik?.errors?.issueCaseFileOne && <FormHelperText sx={{ color: "#D32F2F" }}>{formik?.errors?.issueCaseFileOne}</FormHelperText>}
            </Grid>
          </Grid>
        </>
      );
    case 2:
      return (
        <>
          <TextField
            fullWidth
            label="Case 2 Citation"
            name="caseTwoCitation"
            variant="outlined"
            value={formik.values?.caseTwoCitation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik?.touched?.caseTwoCitation && Boolean(formik?.errors?.caseTwoCitation)}
            helperText={formik?.touched?.caseTwoCitation && formik?.errors?.caseTwoCitation}
            sx={{
              width: "100%",
              mb: "20px",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                color: "#A6A7C1", // Set text color
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: formik?.touched?.caseTwoCitation && Boolean(formik?.errors?.caseTwoCitation) ? "#D32F2F" : "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: formik?.touched?.caseTwoCitation && Boolean(formik?.errors?.caseTwoCitation) ? "#D32F2F" : "#C8C9DB",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: formik?.touched?.caseTwoCitation && Boolean(formik?.errors?.caseTwoCitation) ? "#D32F2F" : "#4A4C64", // Yellow border color on focus
                },
              },
            }}
            InputLabelProps={{
              style: { color: formik.touched?.caseTwoCitation && Boolean(formik.errors?.caseTwoCitation) ? "#D32F2F" : "#C8C9DB" },
            }}
            InputProps={{
              style: {
                color: "#A6A7C1",
              },
            }}
          />
          <TextField
            fullWidth
            label="Fact"
            multiline
            rows={6}
            name="factTwo"
            variant="outlined"
            value={formik.values?.factTwo}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.factTwo && Boolean(formik.errors?.factTwo)}
            helperText={formik.touched?.factTwo && formik.errors?.factTwo}
            sx={{
              width: "100%",
              borderRadius: "5px",
              mb: "20px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#A6A7C1", // Yellow border color on focus
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                // height: "200px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.factTwo} / 5000
          </Typography>
          <TextField
            fullWidth
            label="Law"
            multiline
            rows={4}
            name="lawTwo"
            variant="outlined"
            value={formik.values?.lawTwo}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.lawTwo && Boolean(formik.errors?.lawTwo)}
            helperText={formik.touched?.lawTwo && formik.errors?.lawTwo}
            sx={{
              width: "100%",
              mb: "20px",

              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                height: "120px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.lawTwo} / 5000
          </Typography>
          <TextField
            fullWidth
            label="Ruling"
            multiline
            rows={6}
            name="rullingTwo"
            variant="outlined"
            value={formik.values?.rullingTwo}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.rullingTwo && Boolean(formik.errors?.rullingTwo)}
            helperText={formik.touched?.rullingTwo && formik.errors?.rullingTwo}
            sx={{
              width: "100%",
              mb: "20px",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                height: "200px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.rullingTwo} / 5000
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box
                variant="p"
                onClick={handleUpload}
                sx={{
                  mb: "10px",
                  color: "#C8C9DB",
                  // fontSize: "12px",
                  cursor: "pointer !important",
                }}
              >
                Upload Case 2
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  borderRadius: "4px",
                  borderTop: formik?.values?.issueCaseFileTwo ? "1px solid #4A4C64" : "none",
                  borderBottom: formik?.values?.issueCaseFileTwo ? "1px solid #4A4C64" : "none",
                  borderRight: formik?.values?.issueCaseFileTwo ? "1px solid #4A4C64" : "none",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid #4A4C64",
                    borderRadius: formik?.values?.medicalRecordFile ? "" : "3px",
                    width: "93%",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    sx={{ color: "#686DFC", borderRadius: "2px", border: "1px solid #686DFC", backgroundColor: "transparent", "&:hover , &:focus": { backgroundColor: "transparent" } }}
                  >
                    Choose file
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf,.doc"
                      onChange={(e) => handleFileUploadTwo(e)}
                      error={formik.touched.issueCaseFileTwo && Boolean(formik?.errors?.issueCaseFileTwo)}
                      helperText={formik?.touched?.issueCaseFileTwo && formik?.errors?.issueCaseFileTwo}
                    />
                  </Button>
                  <Typography sx={{ color: "#C8C9DB", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 140px)" }}>
                    {formik?.values?.issueCaseFileTwo?.name ? formik?.values?.issueCaseFileTwo?.name : formik?.values?.issueCaseFileTwo ? formik?.values?.issueCaseFileTwo : "No file selected"}
                  </Typography>
                </Box>
                <Box>
                  {formik?.values?.issueCaseFileTwo && (
                    <Button
                      onClick={handleRemoveFileTwo}
                      sx={{
                        color: "#C8C9DB",
                        backgroundColor: "transparent",
                        minWidth: "35px",
                        "&:hover, &:focus": { background: "transparent" },
                      }}
                    >
                      x
                    </Button>
                  )}
                </Box>
              </Box>
              {formik?.touched?.issueCaseFileTwo && formik?.errors?.issueCaseFileTwo && <FormHelperText sx={{ color: "#D32F2F" }}>{formik?.errors?.issueCaseFileTwo}</FormHelperText>}
            </Grid>
          </Grid>
        </>
      );
    case 3:
      return (
        <>
          <TextField
            fullWidth
            label="Case 3 Citation"
            name="caseThreeCitation"
            variant="outlined"
            value={formik.values?.caseThreeCitation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik?.touched?.caseThreeCitation && Boolean(formik?.errors?.caseThreeCitation)}
            helperText={formik?.touched?.caseThreeCitation && formik?.errors?.caseThreeCitation}
            sx={{
              width: "100%",
              mb: "20px",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                color: "#A6A7C1", // Set text color
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: formik?.touched?.caseThreeCitation && Boolean(formik?.errors?.caseThreeCitation) ? "#D32F2F" : "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: formik?.touched?.caseThreeCitation && Boolean(formik?.errors?.caseThreeCitation) ? "#D32F2F" : "#C8C9DB",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: formik?.touched?.caseThreeCitation && Boolean(formik?.errors?.caseThreeCitation) ? "#D32F2F" : "#4A4C64", // Yellow border color on focus
                },
              },
            }}
            InputLabelProps={{
              style: { color: formik.touched?.caseThreeCitation && Boolean(formik.errors?.caseThreeCitation) ? "#D32F2F" : "#C8C9DB" },
            }}
            InputProps={{
              style: {
                color: "#A6A7C1",
              },
            }}
          />
          <TextField
            fullWidth
            label="Fact"
            multiline
            rows={6}
            name="factThree"
            variant="outlined"
            value={formik.values?.factThree}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.factThree && Boolean(formik.errors?.factThree)}
            helperText={formik.touched?.factThree && formik.errors?.factThree}
            sx={{
              width: "100%",
              borderRadius: "5px",
              mb: "20px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#A6A7C1", // Yellow border color on focus
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                // height: "200px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.factThree} / 5000
          </Typography>
          <TextField
            fullWidth
            label="Law"
            multiline
            rows={4}
            name="lawThree"
            variant="outlined"
            value={formik.values?.lawThree}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.lawThree && Boolean(formik.errors?.lawThree)}
            helperText={formik.touched?.lawThree && formik.errors?.lawThree}
            sx={{
              width: "100%",
              mb: "20px",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                height: "120px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.lawThree} / 5000
          </Typography>
          <TextField
            fullWidth
            label="Ruling"
            multiline
            rows={6}
            name="rullingThree"
            variant="outlined"
            value={formik.values?.rullingThree}
            onChange={handleInputChange}
            onBlur={formik.handleBlur}
            error={formik.touched?.rullingThree && Boolean(formik.errors?.rullingThree)}
            helperText={formik.touched?.rullingThree && formik.errors?.rullingThree}
            sx={{
              width: "100%",
              mb: "20px",
              borderRadius: "5px",
              "& .MuiOutlinedInput-root": {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#4A4C64", // Normal border color
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "lightgray", // Border color on hover
                },
              },
            }}
            InputLabelProps={{
              style: { color: "#C8C9DB" },
            }}
            InputProps={{
              style: {
                height: "200px",
                color: "#A6A7C1",
              },
            }}
          />
          <Typography variant="caption" sx={{ color: "#A6A7C1", display: "block", textAlign: "right", marginBottom: "5px" }}>
            {charCount?.rullingThree} / 5000
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Box
                variant="p"
                onClick={handleUpload}
                sx={{
                  mb: "10px",
                  color: "#C8C9DB",
                  // fontSize: "12px",
                  cursor: "pointer !important",
                }}
              >
                Upload Case 3
              </Box>
              <Box
                component="div"
                sx={{
                  display: "flex",
                  borderRadius: "4px",
                  borderTop: formik?.values?.issueCaseFileThree ? "1px solid #4A4C64" : "none",
                  borderBottom: formik?.values?.issueCaseFileThree ? "1px solid #4A4C64" : "none",
                  borderRight: formik?.values?.issueCaseFileThree ? "1px solid #4A4C64" : "none",
                }}
              >
                {" "}
                <Box
                  component="span"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    border: "1px solid #4A4C64",
                    borderRadius: formik?.values?.medicalRecordFile ? "" : "3px",
                    width: "93%",
                  }}
                >
                  <Button
                    component="label"
                    role={undefined}
                    sx={{ color: "#686DFC", borderRadius: "2px", border: "1px solid #686DFC", backgroundColor: "transparent", "&:hover , &:focus": { backgroundColor: "transparent" } }}
                  >
                    Choose file
                    <VisuallyHiddenInput
                      type="file"
                      accept=".pdf,.doc"
                      onChange={(e) => handleFileUploadThree(e)}
                      error={formik.touched.issueCaseFileThree && Boolean(formik?.errors?.issueCaseFileThree)}
                      helperText={formik?.touched?.issueCaseFileThree && formik?.errors?.issueCaseFileThree}
                    />
                  </Button>
                  <Typography sx={{ color: "#C8C9DB", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 140px)" }}>
                    {formik?.values?.issueCaseFileThree?.name ? formik?.values?.issueCaseFileThree?.name : formik?.values?.issueCaseFileThree ? formik?.values?.issueCaseFileThree : "No file selected"}
                  </Typography>
                </Box>
                <Box>
                  {formik?.values?.issueCaseFileThree && (
                    <Button
                      onClick={handleRemoveFileThree}
                      sx={{
                        color: "#C8C9DB",
                        backgroundColor: "transparent",
                        minWidth: "35px",
                        "&:hover, &:focus": { background: "transparent" },
                      }}
                    >
                      x
                    </Button>
                  )}
                </Box>
              </Box>

              {formik?.touched?.issueCaseFileThree && formik?.errors?.issueCaseFileThree && <FormHelperText sx={{ color: "#D32F2F" }}>{formik?.errors?.issueCaseFileThree}</FormHelperText>}
            </Grid>
          </Grid>
        </>
      );
    default:
      return null;
  }
};
