import React from "react";

function ChatWaitingIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      display="block"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 100 100"
    >
      <circle cx="25" cy="50" r="5" fill="#fff">
        <animate
          attributeName="r"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
          values="5;10;5"
          keyTimes="0;0.5;1"
        />
        <animate
          attributeName="fill-opacity"
          begin="0s"
          dur="0.6s"
          repeatCount="indefinite"
          values="1;0.5;1"
          keyTimes="0;0.5;1"
        />
      </circle>
      <circle cx="50" cy="50" r="5" fill="#fff">
        <animate
          attributeName="r"
          begin="0.2s"
          dur="0.6s"
          repeatCount="indefinite"
          values="5;10;5"
          keyTimes="0;0.5;1"
        />
        <animate
          attributeName="fill-opacity"
          begin="0.2s"
          dur="0.6s"
          repeatCount="indefinite"
          values="1;0.5;1"
          keyTimes="0;0.5;1"
        />
      </circle>
      <circle cx="75" cy="50" r="5" fill="#fff">
        <animate
          attributeName="r"
          begin="0.4s"
          dur="0.6s"
          repeatCount="indefinite"
          values="5;10;5"
          keyTimes="0;0.5;1"
        />
        <animate
          attributeName="fill-opacity"
          begin="0.4s"
          dur="0.6s"
          repeatCount="indefinite"
          values="1;0.5;1"
          keyTimes="0;0.5;1"
        />
      </circle>
    </svg>
  );
}

export default ChatWaitingIcon;
