import React, { useState } from "react";
import { Formik, Form } from "formik";
import { Box, Paper, Button } from "@mui/material";
import { validationSchemaStepOne, validationSchemaStepTwo, validationSchemaStepThree, validationSchemaStepFour, IssueRenderStep } from "../../Validation/IssuseCaseValiation";
import axios from "axios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoaderIcon from "../../Icons/LoaderIcon";
import { useMultiFileUpload } from "../../../context/useMultiFileUploade";
import notificationService from "../../../services/notification.service";
import useStorageValidation from "../../../hooks/useStorageValidation";
const UpdateIssueForm = ({ activeStep, steps, handleBack, scrollToRef, handleSkip, isStepOptional, handleNext, ref }) => {
  const validationSchemas = [validationSchemaStepOne, validationSchemaStepTwo, validationSchemaStepThree, validationSchemaStepFour];
  const currentValidationSchema = validationSchemas[activeStep];
  const user = useSelector((state) => state?.reducer?.user);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const { uploadFiles, uploads } = useMultiFileUpload();
  const { validateAndUpload } = useStorageValidation();
  const location = useLocation();
  const { userData } = location.state || {};
  const initialValues = {
    issueId: userData?._id,
    name: userData?.name || "",
    summaryOfLaw: userData?.summaryOfLaw || "",
    caseOneCitation: userData?.caseOneCitation || "",
    factOne: userData?.factOne || "",
    lawOne: userData?.lawOne || "",
    rullingOne: userData?.rullingOne || "",
    issueCaseFileOne: userData?.issueCaseFileOne || "",
    caseTwoCitation: userData?.caseTwoCitation || "",
    factTwo: userData?.factTwo || "",
    lawTwo: userData?.lawTwo || "",
    rullingTwo: userData?.rullingTwo || "",
    issueCaseFileTwo: userData?.issueCaseFileTwo || "",
    caseThreeCitation: userData?.caseThreeCitation || "",
    factThree: userData?.factThree || "",
    lawThree: userData?.lawThree || "",
    rullingThree: userData?.rullingThree || "",
    issueCaseFileThree: userData?.issueCaseFileThree || "",
  };
  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };
  const handleFormSubmit = (values, actions) => {
    // Check if the fields of the current step are valid
    currentValidationSchema
      .validate(values, { abortEarly: false })
      .then(async () => {
        scrollToRef();
        if (activeStep < validationSchemas.length - 1) {
          actions.setTouched({});
          actions.setSubmitting(false);
          handleNext();
        } else {
          const token = user?.userData?.token;

          const data = {
            ...values,
            fileName1: values.issueCaseFileOne?.name || "",
            fileSize1: values.issueCaseFileOne?.size || 0,
            fileName2: values.issueCaseFileTwo?.name || "",
            fileSize2: values.issueCaseFileTwo?.size || 0,
            fileName3: values.issueCaseFileThree?.name || "",
            fileSize3: values.issueCaseFileThree?.size || 0,
          };
          setIsSubmitting(true);
          const fileSizeValidation = data.fileSize1 + data.fileSize2 + data.fileSize3;
          const validationResult = await validateAndUpload(fileSizeValidation);
          if (validationResult) {
            const response = await axios.put(process.env.REACT_APP_BACKEND_URL + "issue", data, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });

            if (response?.status == 200) {
              const { uniquePathOne, uniquePathTwo, uniquePathThree, updatedIssue } = response.data;

              const uploadConfigs = [
                { file: values.issueCaseFileOne, uniquePath: uniquePathOne, index: 1 },
                { file: values.issueCaseFileTwo, uniquePath: uniquePathTwo, index: 2 },
                { file: values.issueCaseFileThree, uniquePath: uniquePathThree, index: 3 },
              ];

              uploadConfigs.forEach(async ({ file, uniquePath, index }) => {
                if (uniquePath) {
                  await uploadFiles({
                    id: `${updatedIssue?._id}_${index}`,
                    title: `Issue Record - ${values.name}`,
                    file,
                    url: `status/issue/${updatedIssue?._id}/${index}`,
                    uniquePath,
                  });
                }
              });

              if (!uploadConfigs) {
                showSuccessToastMessage("successfull record upated!");
              }

              navigate("/issues");
            }

            actions.setSubmitting(false);
            setIsSubmitting(false);
          }
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          notificationService.error("Storage limit has been exceeded");
        } else {
          notificationService.error("An error occurred. Please try again.");
        }
        actions.setErrors(error);
        actions.setSubmitting(false);
        setIsSubmitting(false);
      });
  };
  const checkIfFieldsAreEmpty = (values) => {
    return !values.caseTwoCitation && !values.factTwo && !values.lawTwo && !values.rullingTwo && !values.issueCaseFileTwo;
  };
  return (
    <Formik initialValues={initialValues} validationSchema={currentValidationSchema} onSubmit={handleFormSubmit} enableReinitialize innerRef={ref}>
      {(formik) => (
        <Form>
          <Paper
            sx={{
              boxShadow: "none",
              width: "100%",
              backgroundColor: "#2B2C40",
              color: "lightgray",
              padding: { xs: 0, sm: 2 },
            }}
          >
            {IssueRenderStep(activeStep, formik)}
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                pt: 2,
              }}
            >
              {activeStep === 0 && (
                <Button
                  variant="outlined"
                  onClick={() => navigate("/issues")}
                  sx={{
                    width: { xs: "100%", sm: "120px" },
                    bottom: "0px",
                    mt: { xs: 1, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    color: "#686DFC",
                    fontSize: "12px",
                    borderColor: "white",
                    border: "2px solid",
                    "&:hover": {
                      border: "2px solid",
                      borderColor: "rgba(104, 109, 252, 0.7)",
                      color: "rgba(104, 109, 252, 0.7)",
                    },
                    "&.Mui-disabled": {
                      border: "2px solid",
                      borderColor: "rgba(104, 109, 252, 0.7)",
                      color: "rgba(104, 109, 252, 0.7)",
                    },
                    fontFamily: "sans-serif",
                  }}
                >
                  Cancel
                </Button>
              )}
              {activeStep > 0 && activeStep <= 3 && (
                <Button
                  onClick={handleBack}
                  variant="outlined"
                  disabled={activeStep === 0}
                  sx={{
                    width: { xs: "100%", sm: "120px" },
                    bottom: "0px",
                    mt: { xs: 1, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    color: "#686DFC",
                    fontSize: "12px",
                    borderColor: "white",
                    border: "2px solid",
                    "&:hover": {
                      border: "2px solid",
                      borderColor: "rgba(104, 109, 252, 0.7)",
                      color: "rgba(104, 109, 252, 0.7)",
                    },
                    "&.Mui-disabled": {
                      border: "2px solid",
                      borderColor: "rgba(104, 109, 252, 0.7)",
                      color: "rgba(104, 109, 252, 0.7)",
                    },
                    fontFamily: "sans-serif",
                  }}
                >
                  Previous
                </Button>
              )}

              <Box sx={{ flex: "1 1 auto" }} />

              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                sx={{
                  width: { xs: "100%", sm: "120px" },
                  mt: { xs: 1, sm: 1.5 },
                  mb: { xs: 0, sm: 2 },
                  boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                  "&:hover, &:focus": {
                    boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                    backgroundColor: "white",
                    color: "#6a1b9a",
                  },
                  "&:disabled": {
                    backgroundColor: "#4A4C64",
                    color: "white",
                    boxShadow: "0px 4px 8px #4A4C64",
                  },
                  fontSize: "12px",
                  backgroundColor: "#686DFC",
                  fontWeight: "600",
                  fontFamily: "poppins",
                }}
              >
                {checkIfFieldsAreEmpty(formik.values) && activeStep === steps.length - 2 ? (
                  isSubmitting ? (
                    <>
                      <LoaderIcon color="#f7f7f7" /> Processing...
                    </>
                  ) : (
                    "Finish"
                  )
                ) : activeStep === steps.length - 1 && isSubmitting ? (
                  <>
                    <LoaderIcon color="#f7f7f7" /> Processing...
                  </>
                ) : activeStep === steps.length - 1 ? (
                  "Finish"
                ) : (
                  "Next"
                )}
              </Button>
            </Box>
          </Paper>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateIssueForm;
