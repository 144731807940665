import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
export const useAxios = () => {
 const navigation= useNavigate()

  const showErrorToastMessage = (msg) => {
    return toast.error(msg, {
      position: "bottom-right",
    });
  };

  const instance = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
  });

  instance.interceptors.response.use(
    (response) => response,
   
    (error) => {
      if(error.response.status === 401)
      {
    
        showErrorToastMessage("Unauthorized, token has expired.", {
          position: "bottom-right",
        });
        navigation('/login')
  
      }
      return Promise.reject(error);
    }
  );


  

  /**
   * @param token
   */
  const setBearerToken = (token) => {
    instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  };
  /**
   * @param token
   */

  /**
   * @param url
   * @param formData
   * @param config Optional configuration for the request
   */
  const post = (url, formData) => {
    return instance.post(url, formData);
  };

  /**
   * @param url
   * @param formData
   */
  const put = (url, formData) => {
    return instance.put(url, formData);
  };

  /**
   * @param url
   * @param formData
   */
  const patch = (url, formData) => {
    return instance.patch(url, formData);
  };

  /**
   * @param url
   */
  const get = (url) => {
    return instance.get(url);
  };

  /**
   * @param url
   */
  const deleteRequest = (url) => {
    return instance.delete(url);
  };

  return {
    post,
    put,
    get,
    patch,
    deleteRequest,
    setBearerToken,
  };
};
