import React from 'react'

const CorespondeceIcon = ({colors}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill={colors}
      d="M15.966 9.543H5.04a.926.926 0 00-.904.833l-.042.484 5.867 4.676 6.725-4.688.04-.471a.752.752 0 00-.76-.834zM1.301 14.118h3.44a.285.285 0 100-.571h-3.44a.286.286 0 000 .571z"
    ></path>
    <path
      fill={colors}
      d="M9.947 16.178a.288.288 0 01-.179-.063l-5.736-4.573-.125 1.433h.836a.858.858 0 010 1.714h-.775a.858.858 0 010 1.714h-.362l-.101 1.153a.78.78 0 00.033.301l2.366-2.175a.286.286 0 11.388.42l-2.388 2.196a.77.77 0 00.361.09h10.927a.88.88 0 00.4-.103l-2.008-2.201a.286.286 0 11.422-.385L16 17.883a.88.88 0 00.096-.328l.523-5.966-6.509 4.536a.279.279 0 01-.163.053z"
    ></path>
    <path
      fill={colors}
      d="M4.255 15.547a.286.286 0 00-.285-.286H.528a.286.286 0 000 .572H3.97a.285.285 0 00.285-.286zM21.554 6.718l.032-.367a.668.668 0 00-.676-.74h-9.712a.824.824 0 00-.805.74l-.034.383 2.8 2.238h2.806c.384 0 .735.152.988.427.101.11.175.238.232.372l4.37-3.053zM10.3 7.418l-.136 1.554h2.08L10.3 7.418zM19.957 9.754c0 .157.128.286.286.286h2.615a.286.286 0 000-.571h-2.615a.285.285 0 00-.286.285z"
    ></path>
    <path
      fill={colors}
      d="M20.002 11.18c0-.22.086-.419.223-.57a.857.857 0 01.02-1.713h1.12l.125-1.438-4.194 2.932v.034l-.267 3.05h3.195a.758.758 0 00.325-.08l-1.776-1.948a.284.284 0 01.018-.402.285.285 0 01.403.017l1.76 1.929a.773.773 0 00.074-.257l.06-.697h-.228a.858.858 0 01-.858-.858z"
    ></path>
    <path
      fill={colors}
      d="M23.473 10.894h-2.615a.286.286 0 000 .571h2.615a.286.286 0 100-.571z"
    ></path>
  </svg>
  )
}

export default CorespondeceIcon
