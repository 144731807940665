import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Grid, TextField, Button, Autocomplete, InputAdornment, FormControl, InputLabel, Select, FormHelperText } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = ["Oliver Hansen", "Van Henry", "April Tucker", "Ralph Hubbard", "Omar Alexander", "Carlos Abbott", "Miriam Wagner", "Bradley Wilkerson", "Virginia Andrews", "Kelly Snyder"];

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
  };
}
const RoleForm = () => {
  const user = useSelector((state) => state?.reducer?.user);
  const userData = useSelector((state) => state?.reducer?.user?.userData);

  const showErrorToastMessage = (msg) => {
    toast.error(msg, {
      position: "bottom-right",
    });
  };
  const showSuccessToastMessage = (msg) => {
    toast.success(msg, {
      position: "bottom-right",
    });
  };

  // const permissionData = PermissionDataValue;
  const navigate = useNavigate();
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const [permissionData, setPermissionData] = useState([]);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      permissionId: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      description: Yup.string(),
      permissionId: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { resetForm }) => {
 
      try {
        const token = userData?.token;
         const formData = new FormData();
        Object.keys(values).forEach((key) => {
          formData.append(key, values[key]);
        });
        const response = await axios.post(process.env.REACT_APP_BACKEND_URL + "roles", formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
        if (response?.status == 200) {
          showSuccessToastMessage("Role has been added successfully.");
          navigate("/role");
        }
        // Handle successful response
      } catch (error) {}
    },
  });

  const getAllPermission = async () => {
    const token = user?.userData?.token;
    try {
      const response = await axios.get(process.env.REACT_APP_BACKEND_URL + "permissions", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status == 200) {
        setPermissionData(response?.data?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAllPermission();
  }, []);

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <Box
      sx={{
        // minHeight: "100vh",
        maxHeight: { xs: 2000, sm: 2000 },
      }}
    >
      <CssBaseline />
      <Box component="form" noValidate onSubmit={formik.handleSubmit} sx={{ mt: 3, position: "relative" }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              error={formik.touched.name && formik.errors?.name}
              autoComplete="given-name"
              name="name"
              required
              id="name"
              label="Role name"
              variant="outlined"
              size="small"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.name}
              helperText={formik.touched?.name && formik.errors?.name}
              sx={{
                width: "100%",
                borderRadius: "5px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.name && Boolean(formik.errors.name) ? "#D32F2F" : "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.name && Boolean(formik.errors.name) ? "#D32F2F" : "#C8C9DB",
                  },
                },
              }}
              InputLabelProps={{
                style: {
                  color: formik.touched?.name && Boolean(formik.errors?.name) ? "#D32F2F" : "#C8C9DB",
                },
              }}
              InputProps={{
                style: {
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ display: "flex" }}>
            {/* <FormControl
              required
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched.permissionId && Boolean(formik.errors.permissionId)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.permissionId && Boolean(formik.errors.permissionId) ? "lightmaroon" : "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.permissionId && Boolean(formik.errors.permissionId) ? "lightmaroon" : "#C8C9DB",
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <InputLabel
                id="permission-label"
                sx={{
                  color: "#C8C9DB",
                  // Change label color
                  "&.Mui-focused": {
                    color: "#A6A7C1", // Change label color when focused
                  },
                }}
              >
                Permission
              </InputLabel>
              <Select
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                multiple
                value={formik.values.permissionId}
                onChange={formik.handleChange}
                input={<OutlinedInput label="Permissions" />}
                MenuProps={MenuProps}
              >
                {permissionData?.map((row) => {
                  return (
                    <MenuItem value={row?._id} key={row?.name}>
                      {row?.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl> */}
            <FormControl
              required
              variant="outlined"
              size="small"
              fullWidth
              error={formik.touched.permissionId && Boolean(formik.errors.permissionId)}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.permissionId && Boolean(formik.errors.permissionId) ? "lightmaroon" : "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: formik.touched.permissionId && Boolean(formik.errors.permissionId) ? "lightmaroon" : "#C8C9DB",
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <InputLabel
                id="permission-label"
                sx={{
                  color: "#C8C9DB",
                  // Change label color
                  "&.Mui-focused": {
                    color: "#A6A7C1", // Change label color when focused
                  },
                }}
              >
                Permission
              </InputLabel>
              <Select
                labelId="permissionId-label"
                id="demo-simple-select"
                autoComplete="off"
                name="permissionId"
                variant="outlined"
                size="small"
                label="permissionId"
                value={formik.values.permissionId}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                // IconComponent={KeyboardArrowDownIcon}
              >
                {permissionData?.map((row) => {
                  return (
                    <MenuItem value={row?._id} key={row?.name}>
                      {row?.name}
                    </MenuItem>
                  );
                })}
              </Select>
              {permissionData?.length <= 0 && <FormHelperText sx={{ color: "#D32F2F" }}>No Permission are added.</FormHelperText>}
              {formik.touched.permissionId && formik.errors.permissionId && <FormHelperText>{formik.errors.permissionId}</FormHelperText>}
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Description"
              multiline
              rows={6}
              name="description"
              variant="outlined"
              value={formik.values?.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched?.description && Boolean(formik.errors?.description)}
              helperText={formik.touched?.description && formik.errors?.description}
              sx={{
                width: "100%",
                borderRadius: "5px",
                mb: "20px",
                "& .MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64", // Normal border color
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "lightgray", // Border color on hover
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#A6A7C1", // Yellow border color on focus
                  },
                },
              }}
              InputLabelProps={{
                style: { color: "#C8C9DB" },
              }}
              InputProps={{
                style: {
                  // height: "200px",
                  color: "#A6A7C1",
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={Object.keys(formik?.errors || {}).length > 0 ? 2 : 3}>
          '{" "}
          <Grid
            item
            xs={12}
            sx={{
              marginTop: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row-reverse" },
              gap: 1,
            }}
          >
            <Button
              type="submit"
              variant="contained"
              // disabled={isSubmitting}
              // disabled={Object.keys(formik?.errors).length > 0}
              // disabled={isSubmitButtonDisabled}
              sx={{
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 0, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                  backgroundColor: "white",
                  color: "#6a1b9a",
                },
                "&:disabled": {
                  backgroundColor: "#4A4C64",
                  color: "white",
                  boxShadow: "0px 4px 8px #4A4C64",
                },
                fontSize: "12px",
                backgroundColor: "#686DFC",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Submit
            </Button>

            <Button
              onClick={() => navigate("/role")}
              variant="contained"
              sx={{
                // position: "absolute",
                bottom: "0px", // Adjust as needed
                width: { xs: "100%", sm: "auto" },
                mt: { xs: 1, sm: 1.5 },
                mb: { xs: 0, sm: 2 },
                boxShadow: "0px 4px 8px #4A4C64",
                "&:hover, &:focus": {
                  boxShadow: "0px 6px 12px #4A4C64",
                  backgroundColor: "darkgray",
                  // color: "#6a1b9a",
                },
                fontSize: "12px",
                backgroundColor: "#4A4C64",
                fontWeight: "600",
                fontFamily: "poppins",
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default RoleForm;
