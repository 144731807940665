import React from 'react'

const DiscoverIcon = ({colors}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 22 27"
  >
    <path
      fill={colors}
      fillRule="evenodd"
      d="M3.646 3.647a6.096 6.096 0 118.62 8.622 6.096 6.096 0 01-8.62-8.622zm.35.35a5.602 5.602 0 107.922 7.923 5.602 5.602 0 00-7.922-7.923zM2.82 2.821a7.266 7.266 0 008.922 11.34.247.247 0 01.303.036l.76.76a.677.677 0 00.954 0l1.196-1.195a.677.677 0 000-.953l-.761-.761a.248.248 0 01-.028-.318A7.266 7.266 0 002.82 2.822V2.82zm13.488 13.488a1.18 1.18 0 000 1.672l3.23 3.232a.316.316 0 00.449 0l1.224-1.225a.319.319 0 000-.449l-3.23-3.23a1.188 1.188 0 00-1.674 0zm-2.085-1.115l.97-.97 1.354 1.354a1.672 1.672 0 00-.97.97l-1.354-1.353zm-5.567-7.52h.291a.29.29 0 10-.29-.291v.29zM6.76 7.589a.29.29 0 00.206.085h.29v-.291a.29.29 0 10-.496.206zm.991 3h.41V8.168h-.41v2.42zM9.953 4.74a2.824 2.824 0 00-4.82 1.997 2.171 2.171 0 00.57 1.628c.34.419.68.836.68 1.924a.3.3 0 00.299.299h.575V8.168h-.291a.785.785 0 11.786-.786v.291h.41v-.29a.785.785 0 11.785.785h-.29v2.42h.575a.3.3 0 00.299-.298c0-1.087.34-1.505.68-1.924a2.17 2.17 0 00.57-1.627 2.815 2.815 0 00-.827-1.997h-.001zm-3.065 6.343v.63a.436.436 0 00.435.434h1.264a.437.437 0 00.434-.434v-.63H6.888zm2.628-.053a.802.802 0 00.509-.741 2.153 2.153 0 01.567-1.613c.341-.42.683-.84.683-1.938a3.319 3.319 0 00-6.637 0c0 1.097.342 1.518.683 1.938a2.153 2.153 0 01.568 1.613.792.792 0 00.505.739v.685a.931.931 0 00.929.929h1.264a.931.931 0 00.929-.929v-.684z"
      clipRule="evenodd"
    ></path>
  </svg>
  )
}

export default DiscoverIcon
