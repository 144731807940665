import React, { useEffect } from 'react';
import { useMultiFileUpload } from '../../context/useMultiFileUploade';
import { toast } from 'react-toastify';

const PageRefreshWrapper = ({ children }) => {
    const { uploads } = useMultiFileUpload();
    let isReloadConfirmed = false;

    const showSuccessToastWarn = (msg) => {
        toast.warn(msg, {
            position: "bottom-right",
            autoClose: 10000, // Increase the display time to 10 seconds (10,000 ms)
        });
    };

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            if (uploads.length > 0) {
                const message = 'There are ongoing uploads. Are you sure you want to refresh the page?';
                event.returnValue = message;

                // Use a timeout to display the toast only if the user confirms the reload
                setTimeout(() => {
                    if (isReloadConfirmed) {
                        showSuccessToastWarn('There are ongoing uploads. Refreshing the page will interrupt the upload process.');
                    }
                }, 0);

                return message;
            }
        };

        const handleUnload = () => {
            isReloadConfirmed = true;
        };

        window.addEventListener('beforeunload', handleBeforeUnload);
        window.addEventListener('unload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
            window.removeEventListener('unload', handleUnload);
        };
    }, [uploads]); 

    return <>{children}</>;
};

export default PageRefreshWrapper;
