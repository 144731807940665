import { Box, Skeleton, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useDebounce from "../../hooks/useDebounce";
import SkeletonComponent from "../skeleton";

const SearchInput = ({ setSearchText, searchText }) => {
  // const [loading, setLoading] = useState(false);
  const debouncedSearchText = useDebounce({ value: searchText });

  // useEffect(() => {
  //   if (debouncedSearchText) {
  //     setLoading(true);
  //     // Simulate a data fetch
  //     setTimeout(() => {
  //       setLoading(false);
  //       // Fetch data based on debouncedSearchText here
  //     }, 1000); // Simulate fetch delay
  //   }
  // }, [debouncedSearchText]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row-reverse",
        alignItems: "center",
      }}
    >
      <TextField
        sx={{
          color: "#C8C9DB",
          borderColor: "lightgray",
          // border: "1px solid white",
          // borderRadius: "2px",
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "lightgray",
            // borderWidth: "1.5px",
          },
          "&:hover   .MuiOutlinedInput-notchedOutline": {
            borderColor: "#C8C9DB !important",
          },

          "& .Mui-focused": {
            color: "red",
          },

          "& .MuiInputLabel-outlined": {
            color: "red",
            borderColor: "red",
            // fontWeight: "bold",
          },
        }}
        label=""
        variant="outlined"
        value={searchText}
        onChange={(event) => setSearchText(event.target.value)}
        InputLabelProps={{
          style: {
            color: "lightgray",
            fontSize: "14px",
            display: "flex",
            alignItems: "center",
          },
        }}
        InputProps={{
          style: { color: "white", height: "40px" },
        }}
      />

      <Typography variant="p" sx={{ display: { xs: "none", sm: "block" }, color: "#C8C9DB" }}>
        Search:&nbsp;&nbsp;
      </Typography>

      {/* {debouncedSearchText && <SkeletonComponent />} */}
    </Box>
  );
};

export default SearchInput;
