import axios from "axios";

// Chatbot service functions
export const postChatbot = async (url, data, config = {}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_CHATBOT_URL}${url}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in postChatbot:", error);
    throw error;
  }
};

export const getChatbot = async (url, config = {}) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_CHATBOT_URL}${url}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in getChatbot:", error);
    throw error;
  }
};

// Other service functions
export const postOtherService = async (url, data, config = {}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND_OTHER_SERVICE_URL}${url}`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in postOtherService:", error);
    throw error;
  }
};

export const getOtherService = async (url, config = {}) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_OTHER_SERVICE_URL}${url}`,
      config
    );
    return response.data;
  } catch (error) {
    console.error("Error in getOtherService:", error);
    throw error;
  }
};

// Example function to fetch a token and use it in a request
export const fetchTokenAndPostChatbot = async (url, data, config = {}) => {
    try {
      // Fetch token
      const tokenResponse = await axios.post(`${process.env.REACT_APP_BACKEND_CHATBOT_URL}/depositions/token`);
  
      // Debugging the full response object
      console.log('Full token response:', tokenResponse);
  
      // Check if the data and access_token fields are present
      if (tokenResponse && tokenResponse.data && tokenResponse.data.access_token) {
        const accessToken = tokenResponse.data.access_token;
        console.log('Access Token:', accessToken);
  
        // Include token in the header
        const authConfig = {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${accessToken}`,
          },
        };
  
        // Perform the post request with the token
        return await axios.post(`${process.env.REACT_APP_BACKEND_CHATBOT_URL}${url}`, data, authConfig);
      } else {
        console.error('Error: Access token not found in response');
        throw new Error('Access token not found in response');
      }
    } catch (error) {
      console.error('Error in fetchTokenAndPostChatbot:', error);
      throw error;
    }
  };
