import React from "react";

const FileUpload = () => {
  return (
    <div>
      <svg width="30" height="30" viewBox="0 0 43 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21.5 41.875C10.5233 41.875 1.625 32.9767 1.625 22C1.625 11.0233 10.5233 2.125 21.5 2.125" stroke="#C8C9DB" stroke-width="3" stroke-linecap="round" stroke-dasharray="8.83 8.83" />
        <path d="M21.5 2.125C32.4767 2.125 41.375 11.0233 41.375 22C41.375 32.9767 32.4767 41.875 21.5 41.875" stroke="#C8C9DB" stroke-width="3" stroke-linecap="round" />
        <path d="M21.5 30.8335V14.271" stroke="#C8C9DB" stroke-width="3" stroke-linecap="round" />
        <path d="M29.2279 21.9997L21.4987 14.2705L13.7695 21.9997" stroke="#C8C9DB" stroke-width="3" stroke-linecap="round" />
      </svg>
    </div>
  );
};

export default FileUpload;
