import React from 'react'

const DispositionsIcon = ({colors}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 28"
    >
      <path
        fill={colors}
        d="M4.553 22v-4.566H5.95V22h1.924v-4.566H9.2V22h1.979v-4.57h1.332V22h1.95v-4.572h1.358V22h1.89v-4.55h1.39V22h1.826v-6.443H2.895V22h1.658zM4.745 14.576c-.09-2.887 1.77-3.893 3.589-4.21a8.591 8.591 0 011.387-.102c1.482-.013 2.965-.013 4.447 0a5.88 5.88 0 012.986.748c1.357.794 1.751 2.06 1.798 3.564H4.745zM8.191 5.974a3.646 3.646 0 117.29.033 3.644 3.644 0 01-3.662 3.717 3.646 3.646 0 01-3.628-3.75z"
      ></path>
      <path
        fill={colors}
        d="M20.425 14.578h-.567a.562.562 0 01-.068-.168c0-.835-.013-1.674 0-2.512 0-.373-.073-.699-.426-.853-.536-.168-1.083-.311-1.605-.51a.603.603 0 01-.349-.814c.121-.286.397-.383.779-.317.906.156 1.497.752 2.013 1.442.131.178.208.39.221.611.013 1.028.002 2.06.002 3.121z"
      ></path>
    </svg>
  )
}

export default DispositionsIcon
