import React from "react";

export default function FeedIcons({ color }) {
  return (
    <div>
      <svg width="24" height="24" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20 3H4C2.897 3 2 3.897 2 5V19C2 20.103 2.897 21 4 21H20C21.103 21 22 20.103 22 19V5C22 3.897 21.103 3 20 3ZM4 19V5H20L20.002 19H4Z" fill={color} />
        <path d="M6 7H18V9H6V7ZM6 11H18V13H6V11ZM6 15H12V17H6V15Z" fill={color} />
      </svg>
    </div>
  );
}
