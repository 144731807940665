import React from 'react'

const IssuesIcon = ({colors}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 28"
  >
    <path
      fill={colors}
      d="M9.766 15.412c.2.2.528.2.728 0l1.295-1.294a.513.513 0 000-.728l-3.64-3.64-.101-.101a.513.513 0 00-.729 0l-1.294 1.294c-.2.2-.2.526 0 .728l3.741 3.741zM12.625 5.277l3.537 3.537.008.008.008.008.086.084a.516.516 0 00.728 0l1.295-1.295c.2-.2.2-.528 0-.728L14.546 3.15a.513.513 0 00-.728 0l-1.295 1.294a.513.513 0 000 .729l.102.104zM13.258 11.38l.006-.006 1.034-1.034 1.358-1.358-3.2-3.201-3.802 3.8 3.201 3.202 1.403-1.402zM21.766 17.761l-7.294-6.92-.708.708 6.872 7.346a.803.803 0 001.13-.002.793.793 0 00.234-.566.793.793 0 00-.234-.566zM14.339 18.209a.788.788 0 00-.786-.787H4.71a.788.788 0 00-.786.787v.334h10.415v-.334zM15.478 19.019H2.786a.788.788 0 00-.786.786v.334h14.262v-.334a.783.783 0 00-.784-.786z"
    ></path>
  </svg>
  )
}

export default IssuesIcon
