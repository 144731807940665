export const GeneratePleadingHeading = [
  {
    id: 1,
    title: "Statement of Material Facts",
  },
  // { id: 2, title: "  Single Assignment of Error" },
  // { id: 3, title: " Single Issue Legal Memorandums" },
  // { id: 4, title: "   Statement of Material Facts" },
  // {
  //   id: 5,
  //   title: " Written Reasons for Judgment",
  // },
];

export const allCategory = [
  { id: 1, title: "Medical Records", values: "medical" },
  { id: 2, title: "Exhibits", values: "exhibits" },
  { id: 3, title: "Discovery", values: "discovery" },
  { id: 4, title: "Depositions",values: "deposition"  },
  { id: 5, title: "Correspondence", values: "correspondence" },
  { id: 6, title: "Pleadings", values: "pleadings" },
];

export const allItem = [
  { id: 1, title: "Deposition of Adam Smith" },
  { id: 2, title: "Deposition of Professor Albert Dsouza" },
  { id: 3, title: "Deposition of Miss Karley Joe" },
  { id: 4, title: "Deposition of Tim Seitz" },
  { id: 5, title: "Deposition of Dr. Kelly Scrantz" },
  { id: 6, title: "Deposition of Miss Karley Joe" },
  { id: 6, title: "Deposition of Adam Smith" },
];
