import * as React from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { MainListItems, mainListItems } from "../constant";
import UploadToaster from "../Components/UploadingToaster";
import RibbonNotification from "../Components/RibonNotification";
import FileUploadToater from "../Components/FileUploadToaster";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import { useAuth } from "../App";
import ConfirmationModel from "../Components/Modal/ConfirmationModel";
import PopupModel from "../Components/Modal";
import { Autocomplete, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Tooltip } from "@mui/material";
import { useQuery } from "react-query";
import { useAxios } from "../services/http.service";
import { useMultiFileUpload } from "../context/useMultiFileUploade";
import LogoutIconSvg from "../Components/Icons/Logout";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} aria-labelledby={`vertical-tab-${index}`} {...other}>
      {value == index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper , &:hover": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    backgroundColor: "#2B2C40",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

export default function MainLayout({ children }) {
  const [tabValue, setTabValue] = React.useState(0);
  const { matter, setMatter } = useMultiFileUpload();
  const { token } = useSelector((state) => state?.reducer?.user?.userData);
  const { get, setBearerToken } = useAxios();
  const fetchCaseData = async () => {
    setBearerToken(token);
    const { data } = await get("/caseDropdown");
    return data?.data;
  };
  const { data: matterData, error, isLoading } = useQuery(["caseDropdown"], fetchCaseData, {});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const initialDrawerState = JSON.parse(localStorage.getItem("drawerOpen")) ?? window.innerWidth > 768;

  const [open, setOpen] = React.useState(initialDrawerState);

  const toggleDrawer = () => {
    setOpen((prevOpen) => {
      const newOpenState = !prevOpen;
      localStorage.setItem("drawerOpen", newOpenState);
      return newOpenState;
    });
  };
  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setOpen(true);
        localStorage.setItem("drawerOpen", true);
      } else {
        setOpen(false);
        localStorage.setItem("drawerOpen", false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  // Define the pages where you want to display the sidebar and heading
  const location = useLocation();
  const user = useSelector((state) => state?.reducer?.user);
  const { logout } = useAuth();
  const handleLogoutConfirm = () => {
    logout();
    setOpenDialog(false);
  };

  const handleLogoutCancel = () => {
    setOpenDialog(false);
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenModel = () => {
    setOpenDialog(true);
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <PopupModel
        open={openDialog}
        styleProp={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "90%", sm: "500px" }, // Set a fixed width
          maxWidth: "100%", // Ensure it does not exceed screen size
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          height: "auto",
          backgroundColor: "#262535",
          boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
          border: "1px solid #686DFC",
        }}
        onClose={handleLogoutCancel}
        cards={<ConfirmationModel handleLogoutConfirm={handleLogoutConfirm} onClose={handleLogoutCancel} />}
      />
      <Box
        sx={{
          display: "flex",
          height: "100vh",
        }}
      >
        <CssBaseline />

        <Drawer variant="permanent" open={open}>
          {/* onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut} */}
          <Toolbar
            sx={{
              display: "flex",
              overflow: "hidden",
              position: "relative",
              alignItems: "center",
              justifyContent: "flex-start",
              px: [1],
              paddingRight: "20px",
            }}
          >
            {/* {open && ( */}
            {/* <Link to={"/"}> */}
            <Box
              component="img"
              src="/images/Logo.png"
              alt="logo"
              sx={{
                maxWidth: "90%",
                height: "auto",
                marginTop: "40px",
                marginBottom: "25px",
              }}
            ></Box>
            {/* </Link> */}
            {/* )} */}
            <Box
              sx={{
                marginTop: "40px",
                marginBottom: "25px",
                marginRight: "-20px",
                // paddingTop: "6px",
                // paddingLeft: "6px",
                // paddingBottom: "6px",
                // paddingRight: "11.96px",
                backgroundColor: "#242333",
                borderRadius: "50%",
              }}
            >
              {/* <IconButton
                sx={{
                  backgroundColor: "#686DFC",
                  width: "23px",
                  height: "23px",
                  color: "white",
                  borderRadius: "50%",
                  zIndex: 5000000,
                  "&:hover": {
                    backgroundColor: "#686DFC",
                  },
                }}
                onClick={toggleDrawer}
              >
                {open ? <ChevronLeftIcon fontSize="small" sx={{ width: "19px", height: "19px" }} /> : <KeyboardArrowRightIcon fontSize="small" sx={{ width: "19px", height: "19px" }} />}
              </IconButton> */}
            </Box>
          </Toolbar>

          <Divider color="#4A4C64" sx={{ height: "0.1%", width: "100%" }} />
          {/* here is search bar  */}
          <Box
            sx={{
              color: "#C8C9DB",
              margin: `3px 10px 0`,
              cursor: "pointer",
            }}
          >
            <Typography sx={{ color: "#C8C9DB", marginBottom: "2px" }}>Select Matter</Typography>
            <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C9DB",
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <Autocomplete
                id="matter-autocomplete"
                options={matterData || []} // Ensure matterData is always an array
                getOptionLabel={(option) => option?.title || ""}
                value={matterData?.find((item) => item._id === matter?._id) || null}
                onChange={(event, newValue) => {
                  setMatter(newValue || "");
                }}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "#FF7799", // Customize the color of the noOptionsText
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Matter Filter"
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                      },
                    }}
                  />
                )}
                sx={{
                  "& .MuiAutocomplete-paper": {
                    backgroundColor: "#323249",
                    color: "#C8C9DB",
                    maxHeight: 200, // Adjust as needed
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                  },
                }}
              />
            </FormControl>
            {/* <FormControl
              variant="outlined"
              size="small"
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "5px",
                  color: "#A6A7C1", // Set text color
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#4A4C64",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C9DB",
                  },
                },
                "& .MuiSvgIcon-root": {
                  color: "#C8C9DB",
                },
              }}
            >
              <Autocomplete
                id="matter-autocomplete"
                options={["All", ...matterData]} // Add "All" option to the beginning of the options
                getOptionLabel={(option) => (option === "All" ? "All Matters" : option?.title || "")} // Display "All Matters" for the "All" option
                value={matter === "All" ? "All" : matterData?.find((item) => item._id === matter?._id) || null} // Handle "All" selection
                onChange={(event, newValue) => {
                  if (newValue === "All") {
                    setMatter("All"); // Set "All" when the user selects the "All" option
                    // Optionally, you can implement logic here to filter for all matters globally
                  } else {
                    setMatter(newValue || ""); // Set the selected value
                  }
                }}
                PaperComponent={({ children }) => (
                  <Paper
                    style={{
                      backgroundColor: "#323249",
                      color: "#C8C9DB",
                      maxHeight: 300, // Adjust as needed
                      overflowY: "auto",
                    }}
                  >
                    {children}
                  </Paper>
                )}
                ListboxProps={{
                  sx: {
                    maxHeight: 200,
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                    "& .MuiAutocomplete-noOptions": {
                      color: "#FF7799", // Customize the color of the noOptionsText
                    },
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    placeholder="Matter Filter"
                    InputProps={{
                      ...params.InputProps,
                      sx: {
                        backgroundColor: "#323249",
                        color: "#C8C9DB",
                      },
                    }}
                  />
                )}
                sx={{
                  "& .MuiAutocomplete-paper": {
                    backgroundColor: "#323249",
                    color: "#C8C9DB",
                    maxHeight: 200, // Adjust as needed
                    overflowY: "auto",
                    "& .MuiAutocomplete-option": {
                      "&:hover": {
                        backgroundColor: "#686DFC26",
                        color: "#686DFC",
                      },
                    },
                  },
                }}
              />
            </FormControl> */}
          </Box>
          <Divider color="#4A4C64" sx={{ height: "0.1%", width: "100%", marginTop: "10px" }} />

          <List component="nav" sx={{ height: "100vh", overflow: "scroll", scrollbarWidth: "none", paddingTop: "0" }}>
            <MainListItems isDrawOpen={open} />
          </List>

          {/* logout section  */}
          <Divider color="#4A4C64" sx={{ height: "0.1%", width: "100%" }} />

          <Toolbar
            sx={{
              display: "flex",
              overflow: "hidden",
              position: "relative",
              alignItems: "center",
              // gap: "20px",
              cursor: "pointer",
              px: [1],
              // py:[1],
              paddingRight: "20px",
              justifyContent: "space-between",
            }}
          >
            <Link to="/" style={{ textDecoration: "none" }}>
              <Tooltip
                title={"Account"} // Tooltip text (full name)
                placement="top" // Position of the tooltip (you can change it to 'bottom', 'left', 'right')
                arrow
              >
                <Typography
                  sx={{
                    p: "5px",
                    backgroundColor: "#F2F3FB",
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    color: "#686DFC",
                    textAlign: "center",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    display: "flex",
                    alignItems: "center", // Vertically center the text inside the circle
                    justifyContent: "center", // Horizontally center the text inside the circle
                    textDecoration: "none",
                  }}
                >
                  {`${user?.userData?.firstName?.charAt(0)}${user?.userData?.lastName?.charAt(0)}`}
                  {/* <PersonIcon /> */}
                </Typography>
              </Tooltip>
            </Link>

            <Typography
              sx={{
                color: "#C8C9DB",
                maxWidth: "150px", // Adjust based on your layout needs
                overflow: "hidden", // Hide overflow text
                textOverflow: "ellipsis", // Show ellipsis for overflowed text
                whiteSpace: "nowrap",
              }}
            >{`${user?.userData?.lastName}       ${user?.userData?.firstName}`}</Typography>
            <Tooltip
              title={"Logout"} // Tooltip text (full name)
              placement="top" // Position of the tooltip (you can change it to 'bottom', 'left', 'right')
              arrow
            >
              <Typography sx={{ color: "#C8C9DB", mt: "7px" }} onClick={handleOpenModel}>
                <LogoutIconSvg color={"C8C9DB"} />
              </Typography>
            </Tooltip>
          </Toolbar>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: "#242333",
            flexGrow: 1,
            height: "100vh",
            overflow: location.pathname === "/queries" ? "hidden" : "auto",
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              mt: 4,
              mb: 4,
              display: open ? { xs: "none", sm: "block" } : { xs: "block", sm: "block" },
            }}
          >
            <Grid container spacing={2}>
              <RibbonNotification />
              {/* children render */}
              {children}
            </Grid>
          </Container>

          <Box component="div" sx={{ position: "absolute", bottom: "20px", right: "57px" }}>
            <UploadToaster />
            <FileUploadToater />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
