import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import OtpInput from "react-otp-input";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { useFormik } from "formik";
// import * as Yup from "yup";
// import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { useAuth } from "../../App";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import { i } from "@table-library/react-table-library/Cell-a4350b14";
import notificationService from "../../services/notification.service";
import { useAxios } from "../../services/http.service";
import { useAuth } from "../../App";
const defaultTheme = createTheme();

const TwoStepVerification = () => {
  const location = useLocation();

  const [otp, setOtp] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const { post } = useAxios();
  if (!location.state) {
    navigate("/login");
  }

  const handleSubmit = async () => {
    if (!otp) {
      notificationService.error('please add the OTP.')
      return;
    }
    try {
      // modelClose();

      const email = location?.state?.email;

      // Assuming 'data' should be created from 'values'
      const data = {
        email: email,
        token: otp,
      };

      const response = await post("VerifyQR", data); // Replace with your actual API endpoint

      if (response.status == 200) {
        if (response?.data?.data?.userData) {
          response.data.data.userData.token = response?.data?.token;
        }
        notificationService.success("Welcome back!");
        login(response?.data?.data);
        const { organizationData } = response?.data?.data;
        organizationData && Object.keys(organizationData).length > 0 ? navigate("/") : navigate("/form");
      }
    } catch (error) {
      if (error.response.data.error) {
        notificationService.error(error.response.data.error || "Invalid OTP. Please try again.");
      }
    }
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box
        sx={{
          minHeight: "100vh",
          maxHeight: { xs: 1000, sm: 1150 },
          backgroundColor: "#222131",
        }}
      >
        <Container component="main" maxWidth="sm" sx={{ paddingY: 4 }}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 10,
              boxShadow: "0px 6px 20px rgba(104, 109, 252, 0.7)",
              border: "1px solid #686DFC",
              padding: "30px",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              backgroundColor: "#222131",
              color: "white",
            }}
          >
            <Link to={"/"}>
              <Avatar alt="Logo" sx={{ m: 2, width: 65, height: 65, cursor: "pointer" }} src="magic_pleadings.png" />
            </Link>
            <Typography component="h1" variant="h5" sx={{ fontFamily: "Recoletabold" }}>
              <span style={{ color: "#686DFC" }}> </span>Two Step Verification
            </Typography>
            <Grid container>
              <Grid item xs={12} sm={12}>
                {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    alignItems: "center",
                    height: "150px",
                    width: "150px",
                    margin: "auto",
                    borderRadius: "50%",
                    marginTop: "10px",
                  }}
                >
                  <img
                    src={location?.state?.qrCodeUrl}
                    alt="image"
                    width={100}
                    height={100}
                  />
                </Box> */}

                {/* <Typography sx={{ fontFamily: "public sans", textAlign: "center", fontSize: "13px", margin: "10px 0 " }}>
                  Please enter the verification code which is send to your registered email address.
                </Typography> */}

                <Box sx={{ background: "##34354C", margin: "10px 0" }}>
                  <Typography sx={{ color: "#C8C9DB", padding: "8px 0", textAlign: "center" }}>Type your 6 digit Verification Code</Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "10px",

                      //   padding: "15px",
                    }}
                  >
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      inputStyle={{
                        color: "#C8C9DB",
                        border: "none",
                        outline: "none",
                        width: "45px",
                        margin: "auto",
                        height: "45px",
                        background: "transparent",

                        borderRadius: "4px",

                        border: "1px solid #67687E",
                      }}
                      numInputs={6}
                      renderSeparator={<span style={{ color: "#fff", margin: "6px" }}></span>} // Optional separator between fields
                      renderInput={(props) => <input style={{ color: "red" }} {...props} />}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",

                  gap: 1,
                  flexDirection: { xs: "column", sm: "row" },
                }}
              >
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{
                    width: { xs: "100%", sm: "auto" },
                    mt: { xs: 0, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    boxShadow: "0px 4px 8px rgba(104, 109, 252, 0.5)",
                    "&:hover, &:focus": {
                      boxShadow: "0px 6px 12px rgba(104, 109, 252, 0.7)",
                      backgroundColor: "white",
                      color: "#6a1b9a",
                    },
                    fontSize: "12px",
                    backgroundColor: "#686DFC",
                    fontWeight: "600",
                    fontFamily: "poppins",
                    // disable color of btn
                    "&:disabled": {
                      backgroundColor: "#4A4C64",
                      color: "white",
                      boxShadow: "0px 4px 8px #4A4C64",
                    },
                  }}
                >
                  submit
                </Button>

                <Button
                  variant="contained"
                  sx={{
                    bottom: "0px", // Adjust as needed
                    width: { xs: "100%", sm: "auto" },
                    mt: { xs: 1, sm: 1.5 },
                    mb: { xs: 0, sm: 2 },
                    boxShadow: "0px 4px 8px #4A4C64",
                    "&:hover, &:focus": {
                      boxShadow: "0px 6px 12px #4A4C64",
                      backgroundColor: "darkgray",
                      // color: "#6a1b9a",
                    },
                    fontSize: "12px",
                    backgroundColor: "#4A4C64",
                    fontWeight: "600",
                    fontFamily: "poppins",
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
            <Link to={"/login"} style={{ textDecoration: "none", display: "flex", alignItems: "center" }} variant="body2">
              <ArrowBackIosIcon sx={{ width: "15px", height: "15px", color: "#686DFC" }} />
              <Grid
                item
                sx={{
                  fontSize: { xs: "16px", sm: "14px" },
                  color: "#686DFC",
                  fontFamily: "poppins",
                }}
              >
                Back to login
              </Grid>
            </Link>
          </Box>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default TwoStepVerification;
