/**
 * Converts a storage size string to MB.
 * @param {string} storage - The storage size string (e.g., "100 MB", "100 GB").
 * @returns {number|null} - The storage size in MB or null if input is invalid.
 */
export const convertToMB = (storage) => {
    if (typeof storage !== 'string' || !storage.trim()) {
      console.error('Invalid storage format');
      return null;
    }
  
    const [value, unit] = storage.trim().toUpperCase().split(' ');
    const numericValue = parseFloat(value);
  
    if (isNaN(numericValue)) {
      console.error('Invalid numeric value');
      return null;
    }
  
    const unitsToMB = {
      MB: 1,
      GB: 1024,
      TB: 1024 * 1024,
    };
  
    const conversionFactor = unitsToMB[unit];
  
    if (!conversionFactor) {
      console.error(`Unsupported unit: ${unit}`);
      return null;
    }
  
    return numericValue * conversionFactor;
  };
  