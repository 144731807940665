import React from "react";

const PDFIcon = () => {
  return (
    <svg width="45" height="45"  viewBox="0 0 21 22" className="mt-16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.6001 16.0164V13.9267M6.6001 13.9267V12.6728C6.6001 12.2791 6.6001 12.0818 6.72883 11.959C6.85755 11.8369 7.06402 11.8369 7.47779 11.8369H8.06292C8.66894 11.8369 9.16045 12.305 9.16045 12.8818C9.16045 13.4586 8.66894 13.9267 8.06292 13.9267H6.6001ZM18.3027 11.8369H17.206C16.5163 11.8369 16.1711 11.8369 15.9571 12.0409C15.7431 12.2448 15.7431 12.5733 15.7431 13.2304V13.9267M15.7431 13.9267V16.0164M15.7431 13.9267H17.5713M13.9142 13.9267C13.9142 15.0802 12.932 16.0164 11.72 16.0164C11.4466 16.0164 11.3104 16.0164 11.2076 15.9604C10.9643 15.8267 10.9886 15.555 10.9886 15.3201V12.5332C10.9886 12.2983 10.9635 12.0267 11.2076 11.8929C11.3095 11.8369 11.4466 11.8369 11.72 11.8369C12.932 11.8369 13.9142 12.7731 13.9142 13.9267Z"
        stroke="white"
        stroke-width="1.25385"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13.2874 19.3596H9.71557C6.99055 19.3596 5.62636 19.3596 4.68013 18.6925C4.41084 18.5037 4.17014 18.277 3.96543 18.0196C3.25659 17.1285 3.25659 15.8463 3.25659 13.2801V11.1527C3.25659 8.67595 3.25659 7.43715 3.64863 6.44828C4.27889 4.85757 5.61132 3.60372 7.3015 3.01023C8.35139 2.6416 9.66625 2.6416 12.2993 2.6416C13.8023 2.6416 14.5546 2.6416 15.1548 2.85225C16.1202 3.19162 16.8817 3.90799 17.242 4.81661C17.4668 5.38167 17.4668 6.08968 17.4668 7.50485V9.32878"
        stroke="white"
        stroke-width="1.25385"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M3.25659 11.0006C3.25659 10.2617 3.55012 9.55303 4.07261 9.03054C4.59509 8.50806 5.30373 8.21453 6.04264 8.21453C6.59935 8.21453 7.25552 8.31149 7.79635 8.16688C8.03267 8.10329 8.24811 7.97868 8.42108 7.80556C8.59405 7.63244 8.71848 7.41689 8.78187 7.18052C8.92648 6.6397 8.82952 5.98352 8.82952 5.42681C8.82974 4.68805 9.12337 3.97962 9.64583 3.45732C10.1683 2.93502 10.8768 2.6416 11.6156 2.6416"
        stroke="white"
        stroke-width="1.25385"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default PDFIcon;
