import React from "react";

const Excel = () => {
  return (
    <svg width="20" height="24" viewBox="0 0 29 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3105_44656)">
        <path
          d="M18.6408 1.632L17.1708 0H5.59435C4.75915 0 4.43635 0.6192 4.43635 1.1028V5.4588H6.05995V1.9836C6.05995 1.7988 6.21595 1.6428 6.39595 1.6428H14.6796C14.862 1.6428 14.9532 1.6752 14.9532 1.8252V7.6092H20.8488C21.0804 7.6092 21.1704 7.7292 21.1704 7.9044V22.0284C21.1704 22.3236 21.0504 22.368 20.8704 22.368H6.39595C6.30669 22.3658 6.22177 22.3291 6.15908 22.2655C6.0964 22.2019 6.06086 22.1165 6.05995 22.0272V20.736H4.44715V22.77C4.42555 23.49 4.80955 24 5.59435 24H21.672C22.512 24 22.7988 23.3916 22.7988 22.8372V6.2244L22.3788 5.7684L18.6408 1.632ZM16.6032 1.824L17.0676 2.3448L20.1828 5.7684L20.3544 5.976H17.1708C16.9308 5.976 16.7788 5.936 16.7148 5.856C16.6508 5.7776 16.6136 5.6524 16.6032 5.4804V1.824ZM15.2952 12.8004H20.7876V14.4012H15.294L15.2952 12.8004ZM15.2952 9.6012H20.7876V11.2008H15.294L15.2952 9.6012ZM15.2952 16.0008H20.7876V17.6016H15.294L15.2952 16.0008ZM1.19995 6.7512V19.5516H13.758V6.7512H1.19995ZM7.47955 14.196L6.71155 15.3696H7.47955V16.8H3.61915L6.41995 12.588L3.93835 8.8008H6.01195L7.48075 11.004L8.94835 8.8008H11.0208L8.53435 12.588L11.3388 16.8H9.18715L7.47955 14.196Z"
          fill="#686DFC"
        />
      </g>
      <defs>
        <clipPath id="clip0_3105_44656">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Excel;
