import React from "react";
import MainLayout from "../../layouts/MainLayout";
import { Box, Grid, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useLocation, useNavigate } from "react-router-dom";
import GetAppIcon from "@mui/icons-material/GetApp";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import useFileActions from "../../hooks/useFileActions";
import moment from "moment";
import useStaticFormattedDate from "../../hooks/useStaticDate";
const ViewDetailIssue = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const { viewRecord } = location.state || {};
  let CreatedDate = "";
  if (viewRecord?.createdAt) {
    const year = new Date(viewRecord?.createdAt).getFullYear();
    const month = new Date(viewRecord?.createdAt).toLocaleString("en-US", {
      month: "long",
    });
    const day = new Date(viewRecord?.createdAt).getDate();
    CreatedDate = `${day ?? ""} ${month ?? ""} ${year ?? ""}`;
  }
  const getFileName = (filePath) => {
    if (typeof filePath !== "string" || !filePath.includes("_")) {
      return filePath; // Return the original filePath if it's not a string or doesn't contain an underscore
    }

    const parts = filePath.split("_");
    return parts.length > 1 ? parts.slice(1).join("_") : filePath;
  };
  const { handleFileAction, FileViewModal } = useFileActions();
  const { date } = useStaticFormattedDate(viewRecord?.createdAt);
  return (
    <MainLayout>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#2B2C40",
                color: "lightgray",
                padding: "20px",
              }}
            >
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    component="div"
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#F2F3FB",
                    }}
                  >
                    {/* , fontWeight: '600', lineHeight: '33.6px', fontSize: '28px' */}
                    <Typography
                      sx={{
                        // fontWeight: "600",
                        lineHeight: "33.6px",
                        fontSize: "28px !important",
                        fontFamily: "Public Sans ,sans-serif",
                      }}
                    >
                      {" "}
                      Issues Details
                    </Typography>
                    <Button
                      onClick={() => navigator("/issues")}
                      variant="outlined"
                      sx={{
                        padding: { xs: "7px 22px", xl: "20px 50px" },
                        color: "#686DFC",
                        fontSize: { xs: "10px", xl: "15px" },
                        borderColor: "white",
                        border: "2px solid",
                        "&:hover": {
                          border: "2px solid",
                          borderColor: "rgba(104, 109, 252, 0.7)",
                          color: "rgba(104, 109, 252, 0.7)",
                        },
                      }}
                    >
                      Back
                    </Button>
                  </Box>
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    marginTop: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        color: "#A6A7C1 !important",
                        fontSize: "12px",
                        lineHeight: "24px",
                      }}
                    >
                      Date Created
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                      }}
                    >
                      {date}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sx={{ marginTop: 2 }}>
                  {/* Case Title Date of certification Note Type Document */}
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#A6A7C1",
                          fontWeight: "500",
                        }}
                      >
                        Issue Name
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          lineHeight: "19.8px",
                          color: "#E6E7F2",
                        }}
                      >
                        {viewRecord?.name}
                      </Typography>
                    </Box>
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#A6A7C1",
                          fontWeight: "500",
                        }}
                      >
                        Summary of Law
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          lineHeight: "19.8px",
                          color: "#E6E7F2",
                          whiteSpace: "normal",
                          wordWrap: "break-word",
                          wordBreak: "break-word",
                        }}
                      >
                        {viewRecord?.summaryOfLaw}
                      </Typography>
                    </Box>
                  </Box>

                  <Box sx={{ display: "flex" }}>
                    {/* Case 1 Citation */}
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#A6A7C1",
                          fontWeight: "500",
                        }}
                      >
                        Case 1 Citation
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          lineHeight: "19.8px",
                          color: "#E6E7F2",
                        }}
                      >
                        {viewRecord?.caseOneCitation}
                      </Typography>
                    </Box>

                    {/* Fact */}
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#A6A7C1",
                          fontWeight: "500",
                        }}
                      >
                        Fact
                      </Typography>
                      <Tooltip title={viewRecord?.factOne} arrow placement="top">
                        <Typography
                          noWrap
                          sx={{
                            fontSize: "18px",
                            lineHeight: "19.8px",
                            color: "#E6E7F2",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "250px", // Adjust as necessary
                            cursor: "pointer",
                          }}
                        >
                          {viewRecord?.factOne}
                        </Typography>
                      </Tooltip>
                    </Box>

                    {/* Law */}
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#A6A7C1",
                          fontWeight: "500",
                        }}
                      >
                        Law
                      </Typography>
                      <Tooltip title={viewRecord?.lawOne} arrow placement="top">
                        <Typography
                          noWrap
                          sx={{
                            fontSize: "18px",
                            lineHeight: "19.8px",
                            color: "#E6E7F2",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "250px", // Adjust as necessary
                            cursor: "pointer",
                          }}
                        >
                          {viewRecord?.lawOne}
                        </Typography>
                      </Tooltip>
                    </Box>

                    {/* Ruling */}
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#A6A7C1",
                          fontWeight: "500",
                        }}
                      >
                        Ruling
                      </Typography>
                      <Tooltip title={viewRecord?.rullingOne} arrow placement="top">
                        <Typography
                          noWrap
                          sx={{
                            fontSize: "18px",
                            lineHeight: "19.8px",
                            color: "#E6E7F2",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "250px", // Adjust as necessary
                            cursor: "pointer",
                          }}
                        >
                          {viewRecord?.rullingOne}
                        </Typography>
                      </Tooltip>
                    </Box>

                    {/* Document */}
                    <Box sx={{ margin: "2px 0 20px 0" }}>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "24px",
                          color: "#A6A7C1",
                          fontWeight: "500",
                        }}
                      >
                        Document
                      </Typography>
                      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Tooltip title={getFileName(viewRecord.issueCaseFileOne)} arrow placement="top">
                          {/* <Typography
                            noWrap
                            sx={{
                              fontSize: "18px",
                              lineHeight: "19.8px",
                              color: "#E6E7F2",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              maxWidth: "250px", // Adjust as necessary
                            }}
                          >
                            {getFileName(viewRecord.issueCaseFileOne)}
                          </Typography> */}
                        </Tooltip>

                        <Box sx={{ display: "flex", gap: "8px" }}>
                          <Typography
                            onClick={() => handleFileAction(viewRecord.fileUrlOne, "view")}
                            target="_blank"
                            sx={{
                              "&:hover": {
                                textDecoration: "underline",
                              },
                              cursor: "pointer",
                              color: "#686DFC",
                            }}
                          >
                            <RemoveRedEyeIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                          </Typography>
                          <FileViewModal />

                          <Typography sx={{ cursor: "pointer" }} onClick={() => handleFileAction(viewRecord.fileUrlOne, "download")}>
                            <GetAppIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Case 2 Citation
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {viewRecord?.caseTwoCitation ? viewRecord?.caseOneCitation : "N/A"}
                    </Typography>
                  </Box>
                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Fact
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {viewRecord?.factTwo ? viewRecord?.factTwo : "N/A"}
                    </Typography>
                  </Box>

                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Law
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {viewRecord?.lawTwo ? viewRecord?.lawTwo : "N/A"}
                    </Typography>
                  </Box>

                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Ruling
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {viewRecord?.rullingTwo ? viewRecord?.rullingTwo : "N/A"}
                    </Typography>
                  </Box>

                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Document
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          lineHeight: "19.8px",
                          color: "#E6E7F2",
                        }}
                      >
                        {viewRecord.issueCaseFileTwo ? getFileName(viewRecord.issueCaseFileTwo) : "N/A"}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <Typography
                          onClick={() => handleFileAction(viewRecord.fileUrlTwo, "view")}
                          target="_blank"
                          sx={{
                            "&:hover": {
                              textDecoration: "underline",
                            },
                            cursor: "pointer",
                            color: "#686DFC",
                          }}
                        >
                          {/* {getValueTable(row, column)} */} <RemoveRedEyeIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                        </Typography>
                        <FileViewModal />

                        <Typography sx={{ cursor: "pointer" }} onClick={() => handleFileAction(viewRecord.fileUrlTwo, "download")}>
                          <GetAppIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Case 3 Citation
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {viewRecord?.caseThreeCitation ? viewRecord?.caseThreeCitation : "N/A"}
                    </Typography>
                  </Box>

                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Fact
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {viewRecord?.factThree ? viewRecord?.factThree : "N/A"}
                    </Typography>
                  </Box>

                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Law
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {viewRecord?.lawThree ? viewRecord?.lawThree : "N/A"}
                    </Typography>
                  </Box>

                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Ruling
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "19.8px",
                        color: "#E6E7F2",
                        whiteSpace: "normal",
                        wordWrap: "break-word",
                        wordBreak: "break-word",
                      }}
                    >
                      {viewRecord?.rullingThree ? viewRecord?.rullingThree : "N/A"}
                    </Typography>
                  </Box>
                  <Box sx={{ margin: "2px 0 20px 0" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        lineHeight: "24px",
                        color: "#A6A7C1",
                        fontWeight: "500",
                      }}
                    >
                      Document
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          lineHeight: "19.8px",
                          color: "#E6E7F2",
                        }}
                      >
                        {getFileName(viewRecord.issueCaseFileThree) ? getFileName(viewRecord.issueCaseFileThree) : "N/A"}
                      </Typography>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <Typography
                          onClick={() => handleFileAction(viewRecord.fileUrlThree, "view")}
                          target="_blank"
                          sx={{
                            "&:hover": {
                              textDecoration: "underline",
                            },
                            cursor: "pointer",
                            color: "#686DFC",
                          }}
                        >
                          {/* {getValueTable(row, column)} */} <RemoveRedEyeIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                        </Typography>
                        <FileViewModal />

                        <Typography sx={{ cursor: "pointer" }} onClick={() => handleFileAction(viewRecord.fileUrlThree, "download")}>
                          <GetAppIcon sx={{ width: "20px", color: "#C8C9DB" }} />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </MainLayout>
  );
};

export default ViewDetailIssue;
