import React from "react";

const BalanceIcon = ({ color }) => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.90606 5.32692H7.85373C7.87479 5.20059 7.9169 5.07426 7.98006 4.96899L8.29588 4.3584H4.92712C4.65341 4.3584 4.44286 4.56895 4.44286 4.84266C4.4218 5.11637 4.63235 5.32692 4.90606 5.32692Z"
        fill="white"
      />
      <path
        d="M15.5595 13.1172H13.5803V10.5696H12.6118V13.1383H5.41108C5.13737 13.1383 4.92682 13.3488 4.92682 13.6225V14.2121C4.92682 14.6121 4.58994 14.949 4.16885 14.949C3.76881 14.949 3.43193 14.6121 3.43193 14.2121V3.60046C3.43193 3.3478 3.36876 3.09514 3.26349 2.86354H9.05356L9.53782 1.89502H1.70544C0.757972 1.89502 0 2.65299 0 3.60046V4.33737C0 4.61109 0.210548 4.82163 0.48426 4.82163H2.46341V14.2121C2.46341 15.1595 3.22138 15.9175 4.16885 15.9175H14.3173C15.2647 15.9175 16.0227 15.1595 16.0227 14.2121V13.6225C16.0437 13.3488 15.8121 13.1172 15.5595 13.1172Z"
        fill={color}
      />
      <path
        d="M8.0624 6.50586H4.90418C4.63047 6.50586 4.41992 6.71641 4.41992 6.99012C4.41992 7.26383 4.63047 7.47438 4.90418 7.47438H8.79932C8.48349 7.20067 8.23084 6.88485 8.0624 6.50586Z"
        fill={color}
      />
      <path
        d="M9.76784 8.63232H4.90418C4.63047 8.63232 4.41992 8.84287 4.41992 9.11658C4.41992 9.3903 4.63047 9.60084 4.90418 9.60084H9.78889C9.76784 9.51662 9.74678 9.41135 9.74678 9.32713V8.88498C9.74678 8.80076 9.74678 8.71654 9.76784 8.63232Z"
        fill={color}
      />
      <path
        d="M4.41992 11.2645C4.41992 11.5382 4.63047 11.7488 4.90418 11.7488H11.1364C11.4101 11.7488 11.6207 11.5382 11.6207 11.2645C11.6207 10.9908 11.4101 10.7803 11.1364 10.7803H4.90418C4.63047 10.7803 4.41992 10.9908 4.41992 11.2645Z"
        fill={color}
      />
      <path
        d="M19.9603 5.39002L18.2969 2.08442C18.2338 1.95809 18.1285 1.89493 18.0022 1.87388L15.7283 1.89493C15.623 1.57911 15.3914 1.3054 15.0966 1.13696V0.694808C15.0966 0.315822 14.7808 0 14.4018 0C14.0228 0 13.707 0.315822 13.707 0.694808V1.13696C13.4122 1.3054 13.1806 1.57911 13.0753 1.89493L10.8014 1.87388C10.6751 1.87388 10.5698 1.95809 10.5067 2.08442L8.84334 5.39002C8.80123 5.4953 8.78017 5.60057 8.80123 5.70585C9.096 6.73753 9.87502 7.22179 10.8225 7.22179C11.77 7.22179 12.57 6.73753 12.8437 5.70585C12.8648 5.60057 12.8648 5.4953 12.8016 5.39002L11.4962 2.82134H13.0543C13.1596 3.13716 13.3912 3.38982 13.6859 3.55826V6.8428C13.6859 7.66394 13.0543 8.35875 12.2332 8.46402L10.9488 8.61141C10.8014 8.63246 10.6962 8.73774 10.6962 8.88512V9.32727C10.6962 9.47465 10.8225 9.60098 10.9699 9.60098H17.7916C17.939 9.60098 18.0653 9.47465 18.0653 9.32727V8.88512C18.0653 8.73774 17.9601 8.63246 17.8127 8.61141L16.5283 8.46402C15.7072 8.3798 15.0756 7.685 15.0756 6.8428V3.57931C15.3703 3.41087 15.5809 3.15822 15.7072 2.8424H17.2653L15.9599 5.41108C15.9177 5.51635 15.8967 5.62163 15.9177 5.7269C16.2125 6.75859 16.9915 7.24285 17.939 7.24285C18.8865 7.24285 19.6866 6.75859 19.9603 5.7269C20.0234 5.62163 20.0024 5.4953 19.9603 5.39002ZM11.8963 5.2637H9.72764L10.8225 3.11611L11.8963 5.2637ZM16.8863 5.2637L17.9601 3.11611L19.0549 5.2637H16.8863Z"
        fill={color}
      />
    </svg>
  );
};

export default BalanceIcon;
