import { createSlice } from "@reduxjs/toolkit";

const initialState = {};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (_, action) => {
      return action.payload;
    },
    clearUser: () => initialState,
    setActiveTab: (state, action) => {
      state.userData = {
        ...state.userData,
        activeTab: action.payload,
      };
    },
    set2faAuth: (state, action) => {
      state.userData = {
        ...state.userData,
        twoFactorEnabled: action.payload,
      };
    },
  },
});

const { setUser, clearUser, setActiveTab, set2faAuth} = userSlice.actions;
const reducer = userSlice.reducer;

const slices = {
  setUser: setUser,
  setActiveTab: setActiveTab,
  clearUser: clearUser,
  set2faAuth:set2faAuth,
  reducer: reducer,
};

export default slices;
