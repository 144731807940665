import React from 'react'

const ExibitsIcon = ({colors}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 29"
  >
    <path
      fill={colors}
      d="M21.302 20.605h-.232v-1.163c0-1.168-.79-2.143-1.86-2.45V12h-1.396v4.884h-2.326V12h-1.395v4.884H9.907V12H8.512v4.884H6.186V12H4.791v4.993a2.553 2.553 0 00-1.86 2.449v1.163h-.233a.698.698 0 000 1.395h18.604a.698.698 0 000-1.395zm-1.628 0H4.326v-1.163c0-.642.521-1.163 1.162-1.163h13.024c.641 0 1.162.521 1.162 1.163v1.163zM3.628 10.837h16.744a.698.698 0 000-1.395h-.268C19.75 5.279 16.254 2 12 2 7.747 2 4.251 5.28 3.896 9.442h-.268a.698.698 0 000 1.395zM12 3.395c3.483 0 6.358 2.655 6.709 6.047H5.29C5.641 6.05 8.517 3.395 12 3.395zm.93 3.024a.93.93 0 11-1.86 0 .93.93 0 011.86 0z"
    ></path>
  </svg>
  )
}

export default ExibitsIcon
